import { Injectable } from "@angular/core"
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router"
import { Observable } from "rxjs"
import { AuthService } from "services/auth.service"
import { tap, map, take } from "rxjs/operators"

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    /**
     * TODO: debug the null account variable immediately following login
     * account is null after login but is an object after page reload, so it was removed
     * map(account => account && this.auth.canAdminUsers(this.auth.roles)),
     */
    return this.auth.account$
      .pipe(
        take(1),
        map(() => this.auth.canAdminUsers(this.auth.roles)),
        tap(boolean => {
          // if (boolean) { // for testing
          if (!boolean) {
            this.router
              .navigate(["/access"])
              .then()
              .catch()
          }
        })
      )
  }
}
