import {enableProdMode} from "@angular/core"
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic"

import {AppModule} from "./app/app.module"
import {environment} from "./environments/environment"

if (environment.production) {
  enableProdMode()
}

// TODO: remove temporary .then which is used to workaround 7.1 serviceworker bug
platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    // if ("serviceWorker" in navigator && environment.production) {
    //   navigator.serviceWorker.register("/ngsw-worker.js")
    // }
  })
  .catch(err => {
    // console.log(err);
  })
