import { Component } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { AuthService } from "services/auth.service"
import { SectionService } from "services/section.service"

@Component({
  template: `
    <ng-container *ngIf="auth.profile$ | async as profile">
      <div
        *ngIf="profile.roles.admin"
        class="flex-wrap"
        style="right: 0; color: #f3f3f3; background-color: #0a0a0a; padding: 0.25em; z-index: 999; width: 100%;"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="1.5em"
      >
        <div>
          <button
            *ngIf="auth.canAdminUsers(profile.roles)"
            routerLink="admin"
            mat-raised-button
            mat-button
            color="accent"
          >
            Admin Pages
          </button>
        </div>
        <ng-container *ngIf="auth.canEdit(profile.roles)">
          <div>
            <mat-slide-toggle
              color="primary"
              (ngModelChange)="toggleMode(profile, 'edit')"
              [ngModel]="profile.modes['edit']"
            >
              Edit
            </mat-slide-toggle>
          </div>
          <!--<ng-container *ngIf="auth.editMode">-->
          <div>
            <mat-slide-toggle
              [disabled]="!profile.modes['edit']"
              color="primary"
              (ngModelChange)="toggleMode(profile, 'layout')"
              [ngModel]="profile.modes['layout']"
            >
              Layout
            </mat-slide-toggle>
          </div>
          <div>
            <mat-slide-toggle
              [disabled]="!profile.modes['edit']"
              color="primary"
              (ngModelChange)="toggleMode(profile, 'content')"
              [ngModel]="profile.modes['content']"
            >
              Content
            </mat-slide-toggle>
          </div>
          <!--</ng-container>-->
        </ng-container>
        <div
          class="clickable"
          routerLink=""
        >
          Admin Toolbar 0.34.8
        </div>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          fxLayoutGap="1.5em"
        >
          <div>
            {{ profile.user.displayName }}
          </div>
          <div style="width: 30px;">
            <img
              style="border-radius: 15px;"
              [src]="profile.user.photoURL"
              alt="user photo"
            >
          </div>
          <button
            mat-raised-button
            mat-button
            color="accent"
            (click)="auth.signOut()"
          >
            Logout
          </button>
        </div>
      </div>
    </ng-container>
  `,
  selector: "app-admin-toolbar",
})
export class AdminToolbarComponent {
  constructor(
    public auth: AuthService,
    private afs: AngularFirestore,
    private sectionService: SectionService
  ) {
  }

  /**
   * TODO: move data writes to service
   */
  toggleMode(profile, mode) {
    console.log("toggleMode")
    this.afs
      .collection("profiles")
      .doc(profile.user.email)
      .set({ modes: { [mode]: !profile.modes[mode] } }, { merge: true })
      .then()
      .catch()
  }

  openAdminPages(event: Event): void {
    event.preventDefault()
    this.sectionService.closeAll(event)
    this.sectionService.sections.admin = true
  }
}
