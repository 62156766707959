import { Component, OnInit } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { AuthService } from "services/auth.service"
import { SectionService } from "services/section.service"
import { AngularFireAnalytics } from "@angular/fire/analytics"

@Component({
  selector: "app-root",
  template: `
    <app-admin-toolbar></app-admin-toolbar>
    <app-header></app-header>
    <!--
    <section [ngClass]="{'progress': true, 'center': true, 'expand': sectionService.progress, 'collapsible': true}">
      <app-progress></app-progress>
    </section>
    -->
    <!--TODO: sectionService variables-->
    <section [ngClass]="{'pricing': true, 'center': true, 'expand': sectionService.sections.pricing, 'collapsible': true}">
      <app-pricing></app-pricing>
    </section>
    <section [ngClass]="{'contact': true, 'center': true, 'expand': sectionService.sections.contact, 'collapsible': true}">
      <app-contact></app-contact>
    </section>
    <section [ngClass]="{'account': true, 'center': true, 'expand': sectionService.sections.account, 'collapsible': true}">
      <app-account></app-account>
    </section>
    <section
      *ngIf="auth.user.email && auth.account"
      [ngClass]="{'cart': true, 'center': true, 'show': sectionService.sections.cart, 'hidden': true}"
    >
      <app-cart></app-cart>
    </section>
    <section
      *ngIf="auth.user.email"
      [ngClass]="{'orders': true, 'center': true, 'show': sectionService.sections.orders, 'hidden': true}"
    >
      <app-orders></app-orders>
    </section>

    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  constructor(
    private angularFireAnalytics: AngularFireAnalytics,
    public sectionService: SectionService,
    public auth: AuthService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (
        event instanceof NavigationEnd &&
        (!this.auth.roles || !this.auth.roles.authenticated || !this.auth.roles.editor)
      ) {
        this.angularFireAnalytics.logEvent("page_view", {
          page_location: event.urlAfterRedirects,
          page_path: event.urlAfterRedirects,
          page_title: event.urlAfterRedirects
        }).then()
        // (<any>window).ga("set", "page", event.urlAfterRedirects);
        // (<any>window).ga("send", "pageview")
      }
    })
    // this.sectionService.sections.account = true;
  }
}
