import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { ContactComponent } from "components/sections/contact.component"
import { FavoritesComponent } from "components/sections/favorites.component"
import { GalleryComponent } from "components/sections/gallery.component"
import { CartComponent } from "components/store/cart.component"
import { CheckoutComponent } from "components/store/checkout.component"
import { OrdersComponent } from "components/store/orders.component"
import { AdminNavComponent } from "components/system/admin-nav.component"
import { UserAccessComponent } from "components/system/user-access.component"
import { AccessGuard } from "guards/access.guard"

import { AdminUsersComponent } from "components/system/admin-users.component"
import { AdminMassEmailComponent } from "components/system/admin-mass-email.component"
import { AdminCartsComponent } from "components/system/admin-carts.component"
import { AdminOrdersComponent } from "components/system/admin-orders.component"

const routes: Routes = [
  { path: "", pathMatch: "full", component: GalleryComponent },
  { path: "favorites", component: FavoritesComponent },
  { path: "checkout", component: CheckoutComponent },
  { path: "cart", component: CartComponent },
  { path: "orders", component: OrdersComponent },
  {
    path: "admin",
    component: AdminNavComponent,
    children: [
      { path: "", redirectTo: "carts", pathMatch: "full" },
      { path: "completed-orders", component: AdminOrdersComponent },
      { path: "post-race-email", component: AdminMassEmailComponent },
      { path: "carts", component: AdminCartsComponent },
      { path: "user", component: AdminUsersComponent },
      { path: "**", component: AdminOrdersComponent },
    ]
  },
  // {path: 'admin/upload-race-spreadsheet', component: someComponent, canActivate: [AdminGuard]},
  { path: "access", component: UserAccessComponent, canActivate: [AccessGuard] },
  { path: "contact", component: ContactComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "home", redirectTo: "" },
  { path: "**", component: GalleryComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
