import { BrowserModule } from "@angular/platform-browser"
import { NgModule } from "@angular/core"
import { environment } from "../environments/environment"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to"
import { InfiniteScrollModule } from "ngx-infinite-scroll"
import { CommonModule } from "@angular/common"
import { AppRoutingModule } from "./app-routing.module"
import { AuthGuard } from "guards/auth.guard"
import { AdminGuard } from "guards/admin.guard"
import { AngularFireModule } from "@angular/fire"
import { AngularFirestoreModule } from "@angular/fire/firestore"
import { AngularFireAuthModule } from "@angular/fire/auth"
import { AngularFireStorageModule } from "@angular/fire/storage"
import { AngularFireFunctionsModule } from "@angular/fire/functions"
import { AngularFireAnalyticsModule, CONFIG, ScreenTrackingService } from "@angular/fire/analytics"
import { FlexLayoutModule } from "@angular/flex-layout"
import { LayoutModule } from "@angular/cdk/layout"
import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatChipsModule } from "@angular/material/chips"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatDialogModule } from "@angular/material/dialog"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatListModule } from "@angular/material/list"
import { MatNativeDateModule } from "@angular/material/core"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"
import { MatRadioModule } from "@angular/material/radio"
import { MatSelectModule } from "@angular/material/select"
import { MatSliderModule } from "@angular/material/slider"
import { MatSlideToggleModule } from "@angular/material/slide-toggle"
import { MatSidenavModule } from "@angular/material/sidenav"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatStepperModule } from "@angular/material/stepper"
import { MatToolbarModule } from "@angular/material/toolbar"
import { MatTooltipModule } from "@angular/material/tooltip"
import { AuthService } from "services/auth.service"
import { AccessGuard } from "guards/access.guard"
import { AppComponent } from "src/app/app.component"
import { BackgroundImageLoadedDirective } from "directives/background-image-loaded.directive"
import { AdminCartLineItemComponent } from "components/system/admin-cart-line-item.component"
import { AdminCartsComponent } from "components/system/admin-carts.component"
import { AdminMassEmailComponent } from "components/system/admin-mass-email.component"
import { AdminOrdersComponent } from "components/system/admin-orders.component"
import { AdminToolbarComponent } from "components/system/admin-toolbar.component"
import { AdminUsersComponent } from "components/system/admin-users.component"
import { MatExpansionModule } from "@angular/material/expansion"
import { AccountComponent } from "components/sections/account.component"
import { AdminNavComponent } from "components/system/admin-nav.component"
import { CartComponent } from "components/store/cart.component"
import { CartIconComponent } from "components/store/cart-icon.component"
import { CartLineItemComponent } from "components/store/cart-line-item.component"
import { CartOptionComponent } from "components/store/cart-option.component"
import { CartOptionsComponent } from "components/store/cart-options.component"
import { CheckoutComponent } from "components/store/checkout.component"
import { CloseComponent } from "components/system/close.component"
import { ConfirmDialogComponent } from "components/dialogs/confirm-dialog.component"
import { ContactComponent } from "components/sections/contact.component"
import { ContactFormComponent } from "components/sections/contact-form.component"
import { FavoritesComponent } from "components/sections/favorites.component"
import { GalleryComponent } from "components/sections/gallery.component"
import { GalleryImageComponent } from "components/sections/gallery-image.component"
import { GalleryNavigatorComponent } from "components/sections/gallery-navigator.component"
import { HeaderComponent } from "components/sections/header.component"
import { ImageDialogComponent } from "components/dialogs/image-dialog.component"
import { MembershipComponent } from "components/sections/membership.component"
import { OrderLineItemComponent } from "components/store/order-line-item.component"
import { OrdersComponent } from "components/store/orders.component"
import { PricingComponent } from "components/sections/pricing.component"
import { ProgressComponent } from "components/sections/progress.component"
import { SalesTaxComponent } from "components/store/sales-tax.component"
import { SearchComponent } from "components/sections/search.component"
import { ShippingAddressComponent } from "components/store/shipping-address.component"
import { TagsComponent } from "components/sections/tags.component"
import { UserAccessComponent } from "components/system/user-access.component"
import { UserProfileComponent } from "components/sections/user-profile.component"
import { ActionsService } from "services/actions.service"

@NgModule({
  declarations: [
    AdminNavComponent,
    AdminToolbarComponent,
    AdminMassEmailComponent,
    AdminOrdersComponent,
    AdminCartsComponent,
    AdminUsersComponent,
    AccountComponent,
    AppComponent,
    BackgroundImageLoadedDirective,
    CartComponent,
    CartIconComponent,
    CartLineItemComponent,
    CartOptionComponent,
    CartOptionsComponent,
    CheckoutComponent,
    CloseComponent,
    ConfirmDialogComponent,
    ContactComponent,
    ContactFormComponent,
    FavoritesComponent,
    GalleryComponent,
    GalleryImageComponent,
    GalleryNavigatorComponent,
    HeaderComponent,
    ImageDialogComponent,
    MembershipComponent,
    OrderLineItemComponent,
    OrdersComponent,
    PricingComponent,
    ProgressComponent,
    SearchComponent,
    TagsComponent,
    UserAccessComponent,
    UserProfileComponent,
    ShippingAddressComponent,
    SalesTaxComponent,
    AdminCartLineItemComponent,
  ],
  imports: [
    AngularFireAnalyticsModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    InfiniteScrollModule,
    LayoutModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot(),
    MatExpansionModule,
  ],
  providers: [
    AccessGuard,
    AdminGuard,
    AuthGuard,
    AuthService,
    MatDatepickerModule,
    ScreenTrackingService,
    ActionsService,
    {
      provide: CONFIG,
      useValue: {
        send_page_view: false,
        anonymize_ip: true
      }
    },
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule {
}
