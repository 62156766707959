import { Component } from "@angular/core"

@Component({
  template: `
    <app-user-profile></app-user-profile>
  `,
  selector: "app-user-access",
})
export class UserAccessComponent {

}
