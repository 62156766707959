import { Component } from "@angular/core"
import { AuthService } from "services/auth.service"

@Component({
  template: `
    <div
      style="display: flex; justify-content: center; flex-wrap: wrap"
    >
      <div style="padding: 10px">
        <app-user-profile></app-user-profile>
      </div>
      <ng-container *ngIf="auth.profile$ | async as profile">
        <div
          *ngIf="profile.roles.authenticated"
          style="padding: 10px"
        >
          <app-membership></app-membership>
        </div>
      </ng-container>
    </div>
  `,
  selector: "app-account",
})
export class AccountComponent {

  constructor(
    public auth: AuthService,
  ) {
  }

}
