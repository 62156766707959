import { Component, Input, OnInit } from "@angular/core"
import { CartService } from "services/cart.service"
import { LineItem } from "interfaces/cart.interface"

@Component({
  template: `
    <span>
    <button
      mat-button color="primary"
      (click)="deleteLineItem($event, lineItem)"
    >
      Delete
    </button>
  </span>
    <span>
    {{ lineItem.quantity }}
  </span>
    <span>
    {{ lineItem.option }}
  </span>
    <span>
    {{ lineItem.image.filename }}
  </span>
  `,
  selector: "app-admin-cart-line-item",
})
export class AdminCartLineItemComponent implements OnInit {
  @Input() lineItem: LineItem

  constructor(
    public cartService: CartService,
  ) {
  }

  ngOnInit(): void {
  }

  deleteLineItem(event, lineItem: LineItem) {
    // console.log(lineItem);
    // console.log(this.auth.account);
    // this.offsetPointer(iCart, iLineItem);
    this.cartService.removeLineItem(event, lineItem)
  }


}
