import { Component } from "@angular/core"
import { AuthService } from "services/auth.service"
import { SectionService } from "services/section.service"

@Component({
  template: `
    <div
      *ngIf="auth.profile$ | async as profile"
      style="display: flex; justify-content: center; align-content: center; align-items: center; justify-items: center; "
    >
      <div
        *ngIf="profile.roles.authenticated"
        style="display: flex; justify-content: center; align-content: center; align-items: center; justify-items: center; flex-wrap: wrap;"
      >
        <div class="profile-image">
          <img [src]="profile.user.photoURL"/>
        </div>

        <div>
          <div>
            <h3>
              {{ profile.user.displayName }}
            </h3>
          </div>
          <button
            (click)="auth.signOut()"
            color="accent"
            mat-raised-button
          >
            Logout
          </button>
        </div>
        <div
          style="width: 100%;"
        >
          <button
            (click)="sectionService.closeOthers($event, 'orders')"
            mat-raised-button
            color="accent"
          >
            View My Orders
          </button>
        </div>
      </div>


      <div
        *ngIf="!profile.roles.authenticated"
      >
        <h3>
          Welcome
        </h3>
        <div>
          Sign in to save your favorites
        </div>
        <div>
          Subscribe to a membership!
        </div>
        <div>
          Order Digitals Online.
        </div>
        <div>
          Print ordering coming soon.
        </div>
        <button
          (click)="auth.googleLogin()"
          mat-raised-button
          color="accent"
        >
          <i class="fa fa-google fa-lg"></i>
          Sign in with Google
        </button>
        <!--
          <button
            class="button-orange"
            (click)="auth.facebookLogin()"
            color="warn"
            mat-raised-button
          >
            <i class="fa fa-facebook fa-lg"></i>
            Connect Facebook
          </button>
        -->

      </div>

      <button *ngIf="false" (click)="auth.githubLogin()" class="button btn-social btn-github" disabled>
        <i class="fa fa-github fa-lg"></i>
        Connect GitHub
      </button>
      <button *ngIf="false" (click)="auth.twitterLogin()" class="button btn-social btn-twitter" disabled>
        <i class="fa fa-twitter fa-lg"></i>
        Connect Twitter
      </button>

    </div>
  `,
  selector: "app-user-profile",
})
export class UserProfileComponent {

  constructor(
    public auth: AuthService,
    public sectionService: SectionService,
  ) {
  }

}
