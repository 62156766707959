import { Component, Input } from "@angular/core"
import { AuthService } from "services/auth.service"
import { ConfirmationsService } from "services/confirmations.service"

@Component({
  template: `
    <ng-container *ngIf="profile$ | async as profile">

      <h3>
        Membership Status:
        {{ profile.memberships.annual || 'NONE' }}
      </h3>

      <div *ngIf="profile.memberships.annual >= thisYear">
        Your membership is current!
      </div>

      <ng-container *ngIf="profile.memberships.annual > 2005 && profile.memberships.annual < thisYear">
        <div *ngIf="membershipLoading">Loading....</div>
        <div *ngIf="!membershipLoading">
          <div>
            Update to a {{ thisYear }} Membership
          </div>
          <app-checkout
            [amount]="5000"
            description="Membership"
            actionMessage="Update your Membership"
            type="membership"
          ></app-checkout>
        </div>
      </ng-container>

      <ng-container *ngIf="profile.memberships.annual < 2006">

        <div *ngIf="membershipLoading">
          Loading....
        </div>

        <div *ngIf="!membershipLoading">
          <div>
            Become a member for $50/year
          </div>
          <div>
            view, save and download
          </div>
          <div>
            all photos at 800 pixels
          </div>
          <div>
            for current and past years
          </div>
          <app-checkout
            [amount]="5000"
            description="Membership"
            actionMessage="Add a Membership"
            type="membership"
          ></app-checkout>
        </div>

      </ng-container>

    </ng-container>
  `,
  selector: "app-membership",
})
export class MembershipComponent {
  @Input()
  details: {
    title: string,
    sub1: string,
    sub2: string,
    price: string
  }[] = []

  profile$ = this.auth.profile$
  membershipLoading = this.auth.membershipLoading
  thisYear = new Date().getFullYear()

  constructor(
    private auth: AuthService,
    private confirmationsService: ConfirmationsService,
  ) {
  }

}
