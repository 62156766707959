import { Injectable } from "@angular/core"
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router"
import { Observable } from "rxjs"
import { AuthService } from "services/auth.service"
import { tap, map, take } from "rxjs/operators"

@Injectable()
export class AccessGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.auth.account$.pipe(
      take(1),
      map(account => !!account),
      tap(boolean => {
        if (!boolean) {
          this.router.navigate(["/login"])
        }
      })
    )
  }
}
