import { Component, HostListener, Input, OnInit } from "@angular/core"
import { AuthService } from "services/auth.service"
import { CartService } from "services/cart.service"
import { AngularFireFunctions } from "@angular/fire/functions"
import { ConfirmationsService } from "services/confirmations.service"
import { CheckoutType, LineItem, Order } from "interfaces/cart.interface"
import { AngularFirestore } from "@angular/fire/firestore"
import { SectionService } from "services/section.service"
import firebase from "firebase/app"
import Timestamp = firebase.firestore.Timestamp

interface User {
  uid: string
  displayName: string
  email: string
}

declare var StripeCheckout: StripeCheckoutStatic

@Component({
  template: `
    <button
      *ngIf="type === 'membership'"
      class="button-orange"
      mat-raised-button
      color="warn"
      (click)="checkout().then().catch(); $event.preventDefault()"
    >
      {{ actionMessage }}
    </button>

    <ng-container *ngIf="type === 'cart'">
      <button
        *ngIf="amount && cartLineItems.length"
        class="button-orange"
        mat-raised-button
        color="accent"
        (click)="checkout().then().catch(); $event.preventDefault()"
        [disabled]="!cartService.shippingAddress && !!cartService.shippable"
      >
        <ng-container *ngIf="!auth.checkoutPending">
          {{ amount / 100 | currency }}
          {{ actionMessage }}
        </ng-container>
        <ng-container *ngIf="auth.checkoutPending">
          Your Order is Processing
        </ng-container>
      </button>

      <button
        *ngIf="!amount"
        class="button-orange"
        mat-raised-button
        color="accent"
      >
        Your Cart is Empty!
      </button>

    </ng-container>
  `,
  selector: "app-checkout",
})
export class CheckoutComponent implements OnInit {
  @Input() amount: number
  @Input() description: string
  @Input() actionMessage: string
  @Input() type: CheckoutType
  @Input() cartLineItems: LineItem[]

  /**
   * if type === 'membership' then description is 'Membership' and orderId is undefined
   * if type === 'cart' then description is 'Cart Order' and orderId is newDocId, we convert the cart to an order when checkout succeeds
   */

  handler: StripeCheckoutHandler
  confirmation: any

  constructor(
    public auth: AuthService,
    public cartService: CartService,
    private afs: AngularFirestore,
    private functions: AngularFireFunctions,
    private confirmationService: ConfirmationsService,
    private sectionService: SectionService,
  ) {
  }

  /**
   * Stripe keys are stored here: https://dashboard.stripe.com/apikeys
   *
   * private key: live key
   * key: 'pk_live_ymT0VQnjXj4opuurx4N55OwJ',
   *
   * private key: test key
   * key: 'pk_test_lkjBGi3LrTlAaZkWnuZh7dhW',
   *
   * secret key: add the secret key to the environment
   * firebase functions:config:set stripe.secret="sk_your_test_key or sk_your_live_key"
   */
  ngOnInit() {
    const metadata = {
      orderId: this.cartService.getNewDocId(),
      uid: this.auth.account.uid,
      name: this.auth.user.displayName,
    }
    this.handler = StripeCheckout.configure({
      key: "pk_live_ymT0VQnjXj4opuurx4N55OwJ",
      image: "https://www.xtrphoto.com/assets/icons/icon-72.png",
      locale: "auto",
      source: async source => {
        this.auth.membershipLoading = true
        this.auth.checkoutPending = true
        if (this.cartLineItems) {
          const batchProcessing = this.afs.firestore.batch()
          this.cartLineItems.forEach(cartLineItem => {
            cartLineItem.status = "processing"
            batchProcessing.set(this.afs.collection("cart-line-items").doc(cartLineItem.id).ref, cartLineItem)
          })
          batchProcessing
            .commit()
            .then(() => {
              console.log()
            })
            .catch(() => {
              console.log()
            })
        }
        // this.afs
        //   .collection('carts')
        //   .doc(this.auth.account.uid)
        //   .set({orderStatus: 'processing'}, {merge: true})
        //   .then()
        //   .catch();
        const user = await this.auth.getUser() as User
        const stripeFunction = this.functions.httpsCallable("stripeCreateCharge")
        const data = {
          source: source.id,
          uid: user.uid,
          amount: this.amount,
          description: this.description,
          metadata: metadata,
        }
        this.confirmation = await stripeFunction(data).toPromise()
        // console.log(this.confirmation)
        /**
         * checkout has succeeded!
         */
        if (this.confirmation.status === "succeeded"
          && this.type === "cart"
          && this.confirmation.description === "Cart Order"
          && this.confirmation.metadata.orderId
        ) {
          const confirmationOrderAndCartLineItemDateUpdated = this.auth.getDate()
          const shippingInfo = this.cartService.shippingAddressValues
          const order: Order = {
            amount: this.confirmation.amount,
            cartLineItems: this.cartLineItems,
            dateUpdated: Timestamp.fromMillis(this.confirmation.created * 1000),
            dateUpdatedString: confirmationOrderAndCartLineItemDateUpdated.isoDateTimeHourMin,
            dateUpdatedTimestamp: confirmationOrderAndCartLineItemDateUpdated.timeStamp,
            id: this.confirmation.metadata.orderId,
            status: "completed",
            uid: user.uid,
            userEmail: user.email,
            userDisplayName: user.displayName,
            shippingName: shippingInfo.name,
            shippingAddress: shippingInfo.address,
            shippingAddress2: shippingInfo.address2,
            shippingCityStateZip: shippingInfo.city + ", " + shippingInfo.state + " " + shippingInfo.postalCode,
          }

          const batch = this.afs.firestore.batch()
          this.cartLineItems.forEach(cartLineItem => {
            const updatedCartLineItem = {
              ...cartLineItem,
              orderId: this.confirmation.metadata.orderId,
              dateUpdated: Timestamp.fromMillis(this.confirmation.created * 1000),
              dateUpdatedString: confirmationOrderAndCartLineItemDateUpdated.isoDateTimeHourMin,
              dateUpdatedTimestamp: confirmationOrderAndCartLineItemDateUpdated.timeStamp,
              status: "completed",
            }
            batch.set(this.afs.collection("order-line-items").doc(cartLineItem.id).ref, cartLineItem)
            batch.delete(this.afs.collection("cart-line-items").doc(cartLineItem.id).ref)
          })
          batch.set(this.afs.collection("orders").doc(this.confirmation.metadata.orderId).ref, order)
          // batch.set(this.afs.collection('carts').doc(user.uid).ref, {orderStatus: ''}, {merge: true})

          batch.commit()
            .then(() => {
              this.auth.membershipLoading = false
              this.auth.checkoutPending = false
              this.confirmationService.addRecord(this.confirmation)
              this.sectionService.closeOthers(null, "orders")
            })
            .catch(() => {
              console.log()
            })
        } else {
          this.auth.membershipLoading = false
          this.auth.checkoutPending = false
          this.confirmationService.addRecord(this.confirmation)
        }
      }
    })
  }

  // Open the checkout handler
  async checkout() {
    if (this.cartService.cartCheckoutDisabled) {
      alert("Cart Checkout is temporarily DISABLED: you may have another xtrPhoto session active." +
        " Reload this session to ENABLE the Cart Checkout (Refresh the browser)" +
        " or switch to your other active xtrPhoto session")
/*
      if (this.auth.user.email === 'lowell@digitalfarrier.com') {
        await this.createTestOrder()
      }
*/
    }
    if (!this.cartService.cartCheckoutDisabled && !this.auth.checkoutPending) {
      const user = await this.auth.getUser() as User
      this.handler.open({
        name: "xtrPhoto Store",
        amount: this.amount,
        description: this.description,
        email: user.email,
      })
    }
  }

  // Close on navigate
  @HostListener("window:popstate")
  onPopstate() {
    this.handler.close()
  }

  async createTestOrder() {
    const orderId = this.cartService.getNewDocId()
    const user = await this.auth.getUser() as User
    const createTestOrderDateUpdated = this.auth.getDate()
    const order: Order = {
      amount: 111,
      cartLineItems: this.cartLineItems,
      dateUpdatedString: createTestOrderDateUpdated.isoDateTimeHourMin,
      dateUpdatedTimestamp: createTestOrderDateUpdated.timeStamp,
      id: orderId,
      status: "completed",
      uid: user.uid,
      userEmail: user.email,
      userDisplayName: user.displayName,
      shippingName: null,
      shippingAddress: null,
      shippingAddress2: null,
      shippingCityStateZip: null,
    }
    await this.afs.collection("orders").doc(orderId).set(order)

  }
}
