import { Injectable } from "@angular/core"
import { DataService } from "services/data.service"
import { LogService } from "services/log.service"

@Injectable({
  providedIn: "root"
})
export class ConfirmationsService {
  constructor(
    private dataService: DataService,
    private logService: LogService,
  ) {
  }

  addRecord(confirmation: any) {
    if (confirmation.livemode) {
      const userEmail = confirmation.source.owner.email
      const data = { [confirmation.id]: confirmation }
      /**
       * TODO: write to confirmations and profile collections from cloud functions, remove owner permission to write to these collections
       */
      console.log("addRecord")
      this.dataService
        .insertData("confirmations", userEmail, data)
        .then(() => {
        })
        .catch(() => {
        })
      if (confirmation.status === "succeeded") {
        /**
         * TODO: make conditional more specific
         * check confirmation description, date, amount and status before assigning perms.
         */
        if (confirmation.description === "Membership" && confirmation.amount === 5000) {
          console.log("confirmation")
          let membershipYear = new Date(confirmation.created * 1000).getFullYear()
          if (new Date(confirmation.created * 1000).getMonth() === 11) {
            membershipYear++
          }
          this.dataService
            .insertData(
              "profiles",
              userEmail,
              {
                memberships: {
                  annual: membershipYear
                }
              }
            )
            .then(() => {
              console.log()
            })
            .catch(() => {
              console.log()
            })
        }
        if (confirmation.description === "Cart Order" && confirmation.metadata.orderId) {
          this.log("", "addRecord")
          /**
           * TODO: get cart by id using confirmation.description
           * TODO: convert cart to completed order
           * TODO: process cart items and link to user account
           */
        }
      }
    }
  }

  private log(type, message) {
    const enabled = true
    // const enabled = false
    if (enabled) {
      if (type === "constructor") {
        this.logService.log(type, "confirmation.service." + message)
      } else {
        this.logService.log(type, "confirmation.service." + message)
      }
    }
  }

  /**
   * Sample this.confirmation result after successful stripe transaction
   *
   {
   amount: 5000
   amount_refunded: 0
   application: null
   application_fee: null
   application_fee_amount: null
   balance_transaction: "txn_1EVTI2AzNJJ0PQAZsPGO3moR"
   billing_details: {
   address: {
   city: null
   country: null
   line1: null
   line2: null
   postal_code: null
   state: null
   }
   email: "lowell@digitalfarrier.com"
   name: "lowell@digitalfarrier.com"
   phone: null
   }
   captured: true
   created: 1556758845
   currency: "usd"
   customer: "cus_EzQyHeWQHIpOjL"
   description: null
   destination: null
   dispute: null
   failure_code: null
   failure_message: null
   fraud_details: {}
   id: "ch_1EVTI1AzNJJ0PQAZLHkMNTg3"
   invoice: null
   livemode: false
   metadata: {}
   object: "charge"
   on_behalf_of: null
   order: null
   outcome: {
   network_status: "approved_by_network"
   reason: null
   risk_level: "normal"
   risk_score: 37
   seller_message: "Payment complete."
   type: "authorized"
   }
   paid: true
   payment_intent: null
   payment_method: "src_1EVTHxAzNJJ0PQAZoryQRStw"
   payment_method_details: {
   card: {
   brand: "visa"
   checks: {
   address_line1_check: null
   address_postal_code_check: null
   cvc_check: "pass"
   }
   country: "US"
   description: "Visa Classic"
   exp_month: 4
   exp_year: 2024
   fingerprint: "iXzQ3ooMIIoji5YJ"
   funding: "credit"
   last4: "4242"
   three_d_secure: null
   wallet: null
   }
   type: "card"
   }
   receipt_email: null
   receipt_number: null
   receipt_url: "https://pay.stripe.com/receipts/acct_1DMk2rAzNJJ0PQAZ/ch_1EVTI1AzNJJ0PQAZLHkMNTg3/rcpt_EzSC5jz6dkTVuSubpiTQByKHn6egVQu"
   refunded: false
   refunds: {
   data: []
   has_more: false
   object: "list"
   total_count: 0
   url: "/v1/charges/ch_1EVTI1AzNJJ0PQAZLHkMNTg3/refunds"
   }
   review: null
   shipping: null
   source: {
   amount: null
   card: {
   address_line1_check: null
   address_zip_check: null
   brand: "Visa"
   country: "US"
   cvc_check: "pass"
   dynamic_last4: null
   exp_month: 4
   exp_year: 2024
   fingerprint: "iXzQ3ooMIIoji5YJ"
   funding: "credit"
   last4: "4242"
   name: null
   three_d_secure: "optional"
   tokenization_method: null
   }
   client_secret: "src_client_secret_EzSChmVNxNVLVW2cqwiMdZv9"
   created: 1556758846
   currency: null
   customer: "cus_EzQyHeWQHIpOjL"
   flow: "none"
   id: "src_1EVTHxAzNJJ0PQAZoryQRStw"
   livemode: false
   metadata: {}
   object: "source"
   owner: {
   address: null
   email: "lowell@digitalfarrier.com"
   name: "lowell@digitalfarrier.com"
   phone: null
   verified_address: null
   verified_email: null
   verified_name: null
   verified_phone: null
   }
   statement_descriptor: null
   status: "chargeable"
   type: "card"
   usage: "reusable"
   }
   source_transfer: null
   statement_descriptor: null
   status: "succeeded"
   transfer_data: null
   transfer_group: null
   }

   */

}
