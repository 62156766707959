
export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyD4hZ_o8GUEwGFd7oHg9IAISC8F7D-DLhA",
    authDomain: "xtrphotoprod.firebaseapp.com",
    databaseURL: "https://xtrphotoprod.firebaseio.com",
    projectId: "xtrphotoprod",
    storageBucket: "xtrphotoprod.appspot.com",
    messagingSenderId: "295342411065",
    appId: "1:295342411065:web:af993cc92150f311",
    measurementId: "G-R4FRPPTBJQ"
  },
}
