import { Component, Input, OnInit } from "@angular/core"
import { Image } from "interfaces/image.interface"
import { CartService } from "services/cart.service"
import { Observable } from "rxjs"
import { CartOption, LineItem } from "interfaces/cart.interface"

@Component({
  template: `
    <ng-container *ngIf="cartOptions$ | async as cartOptions">
      <ng-container *ngFor="let cartOption of cartOptions">
        <app-cart-option
          [cartOption]="cartOption"
          [image]="image"
          [cartOptions]="cartOptions"
        ></app-cart-option>
      </ng-container>
    </ng-container>
  `,
  selector: "app-cart-options",
})
export class CartOptionsComponent implements OnInit {
  @Input() image: Image
  cartOptions$: Observable<CartOption[]>

  constructor(
    private cartService: CartService,
  ) {
  }

  ngOnInit(): void {
    this.cartOptions$ = this.cartService.cartOptions$
    // .pipe(
    //   tap(cartOptions => {
    //     cartOptions.map(cartOption => {
    //       if (cartOption.type === 'digital') {
    //         const match = this.cartLineItems
    //           .filter(e => e.option === cartOption.option && e.image.filename === this.image.filename);
    //         cartOption.disabled = !!match.length;
    //       }
    //     });
    //   })
    // );
  }

}
