import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { MatDialog } from "@angular/material/dialog"
import { ConfirmDialogComponent } from "components/dialogs/confirm-dialog.component"
import { Observable, Subscription } from "rxjs"
import { tap } from "rxjs/operators"
import { AuthService } from "services/auth.service"
import { GalleryService } from "services/gallery.service"
import { EmailService } from "services/email.service"
import firebase from "firebase/app"
import "firebase/firestore"
import { Folder } from "interfaces/folder.interface"
import { Favorites } from "interfaces/account.interface"

@Component({
  template: `
    <ng-container *ngIf="items$ | async as items">
      <div
        *ngIf="items.length"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="flex-wrap"
        style="padding: 2px 0;"
      >
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="center center" class="flex-wrap">
          <ng-container *ngFor="let item of items">
            <button
              mat-raised-button
              [color]="color[level]"
              style="margin: 2px 0; border-radius: 5px; padding: 8px; line-height: 16px;"
              [style.color]="galleryService.isFolderSelected(item) ? '#de6e2f' : undefined"
              [style.font-weight]="galleryService.isFolderSelected(item) ? 600 : undefined"
              (click)="clickEventButton(item)"
            >
              <mat-icon
                *ngIf="isEmailGroup(item) && auth.canEdit(auth.roles)"
                inline
                (click)="myClick($event, item)"
                matTooltip
              >
                file_upload
              </mat-icon>
              {{ galleryService.folderLabel(item) }}
            </button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  `,
  selector: "app-gallery-navigator",
})
export class GalleryNavigatorComponent implements OnInit, OnDestroy {
  @Input() level: number
  @Input() selection
  @Input() type: string
  color: string[]
  items$: Observable<Folder[]>
  subscription: { [key: string]: Subscription } = {}
  favorite: boolean
  favorites: Favorites

  constructor(
    public auth: AuthService,
    public galleryService: GalleryService,
    public dialog: MatDialog,
    private afs: AngularFirestore,
    private emailService: EmailService,
  ) {
  }

  ngOnInit() {
    this.galleryService.imageInRoute = false
    this.color = ["primary", "", "primary", "", "primary", "", "primary", "", "primary", ""]

    if (this.type === "favorites") {
      this.getFavorites()
    } else if (this.level + 1 <= this.galleryService.selections.length) {
      this.getLevel(this.type)
    }
  }

  ngOnDestroy(): void {
    for (const key in this.subscription) {
      this.subscription[key].unsubscribe()
    }
  }

  private getFavorites() {
    if (this.auth.favorites$) {
      this.subscription["favSubscription"] = this.auth.favorites$
        .pipe(
          tap(favorites => {
            if (favorites) {
              this.galleryService.buildImageArray(favorites, "favorites")
            }
          })
        )
        .subscribe()
    }
  }

  private getLevel(type) {
    const routeParts = this.galleryService.getRouteParts()
    this.items$ = this.afs
      .collection("folders", ref => {
        let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref
        this.galleryService.selections.forEach((selection, index) => {
          if (index + 1 === this.galleryService.selections.length) {
            query = query.where("folderMap.length", "==", index + 1)
          }
          if (index + 1 !== this.galleryService.selections.length) {
            query = query.where("folderMap.level" + index, "==", this.selection[0].folderMap["level" + [index]])
          }
        })
        return query
      })
      .valueChanges()
      .pipe(
        tap((docs: Folder[]) => {
          docs
            .sort((a, b) => {
              if (a["folderPath"] < b["folderPath"]) {
                return -1
              }
              if (a["folderPath"] > b["folderPath"]) {
                return 1
              }
              return 0
            })
          if (docs.length && type !== "favorites") {
            this.galleryService.items = docs
            this.galleryService.buildImageArray(docs, "docs")
            if (this.level + 1 <= routeParts.length) {
              this.galleryService.items.forEach(item => {
                if (item.folderMap["level" + this.level] === routeParts[this.level]) {
                  this.galleryService.selectFolder(item)
                  this.galleryService.urlImageMatch()
                }
              })
              /*
                            if (routeParts[this.level + 1] !== undefined && routeParts[this.level + 1].includes('.JPG')) {
                              this.galleryService.imageInRoute = true;
                            }
              */
            }
            this.galleryService.activatedRoute.queryParamMap
              .subscribe(params => {
                this.galleryService.newTag = params["params"].tag
                this.galleryService.updateSearch()
              })
          }
        }),
      )
  }

  isFolderShown(level: number, folder: Folder) {
    const segments = folder["segments"]

    if (level === 0 && segments.length === 1) {
      // initial condition - top level folders
      return true
    }

    if (level + 1 !== segments.length) {
      // folder at wrong level
      return false
    }
    // check if parent is selected
    return folder["folderPath"].startsWith(this.galleryService.selections[level - 1][0]["folderPath"])
  }

  clickEventButton(item: Folder) {
    if (this.auth.roles.authenticated && this.auth.modes.edit) {
      this.confirmDialog({ doc: item })
    } else {
      this.galleryService.setRoute(item.folderPath)
      this.galleryService.selectFolder(item)
    }
  }

  // mat-dialog stuff
  private confirmDialog(data): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "50%",
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res !== undefined) {
        if (res.delete === true) {
          console.log("delete folder doc")
          this.afs.doc("folders/" + data.doc.id)
            .delete()
            .then()
            .catch()
        } else {
          if (res.searchable !== undefined) {
            console.log("searchable")
            this.afs.doc("folders/" + data.doc.id)
              .set({ searchable: res.searchable }, { merge: true })
              .then()
              .catch()
          }
          if (res.public !== undefined) {
            console.log("public")
            this.afs.doc("folders/" + data.doc.id)
              .set({ public: res.public }, { merge: true })
              .then()
              .catch()
          }
          if (res.applyLabel === true) {
            console.log("label")
            this.afs.doc("folders/" + data.doc.id)
              .set({ label: res.label }, { merge: true })
              .then()
              .catch()
          }
        }
      }
    })
  }

  myClick(event: MouseEvent, item: Folder) {
    this.emailService.year = item.folderMap["level0"]
    this.emailService.series = item.folderMap["level1"]
    this.emailService.race = item.folderMap["level2"]
    this.galleryService.router
      .navigate(["admin/post-race-email"])
      .then()
      .catch()
  }

  isEmailGroup(item: Folder) {
    const folderPath: string = item.folderPath
    const splitPath: string[] = folderPath.split("/")
    const index = splitPath.indexOf("WORS Mountain Bike Series")
    return index !== -1 && index === splitPath.length - 2
  }

}
