import { Component } from "@angular/core"

@Component({
  template: `
    <div style="display: flex; flex-wrap: wrap; justify-content: center">
      <div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <mat-card style="margin: 1rem;">
            <div class="title">
              Extreme Photography Unlimited
            </div>
            <div>
              Gary Smits
            </div>
            <div class="">
              300 Swiss Hill Dr
            </div>
            <div>
              Green Bay, WI 54302
            </div>
            <div>
              <a href="tel: 9202651782">
                (920) 265-1782
              </a>
            </div>
            <div class="subtext">
              I’ve been photographing races since 2004.
            </div>
            <div>
              - Gary Smits
            </div>
          </mat-card>
          <div style="width: 200px;">
            <img src="/assets/images/fatbiker.jpg">
          </div>
        </div>
      </div>
      <div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <div style="width: 350px;">
            <img src="/assets/images/trailer.jpg">
          </div>
          <mat-card style="width: 80%; margin-left: auto; margin-right: auto; text-align: center;">
            <app-contact-form></app-contact-form>
          </mat-card>
        </div>
      </div>
    </div>
  `,
  selector: "app-contact",
})
export class ContactComponent {
}
