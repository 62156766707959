import { Component, Input, OnInit } from "@angular/core"
import { CartService } from "services/cart.service"
import { Image } from "interfaces/image.interface"
import { CartOption, LineItem } from "interfaces/cart.interface"
import { AuthService } from "services/auth.service"
import { DataService } from "services/data.service"
import { Observable } from "rxjs"

@Component({
  styles: [`
    button {
      height: 3.5rem;
      line-height: 1rem;

      margin: .5rem;
    }

    button .tag {
      bottom: -.25rem;
      left: 0rem;
      height: 18px;
      background-color: #f4eca7c2;
      border-radius: 0px 4px 4px 0px;
      margin-left: 9px; /* This makes room for the triangle */
      position: relative;
      color: black;
      font-weight: 800;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 12px;
      line-height: 18px;
      padding: 0 3px 0 0;
    }

    /* Makes the triangle */

    button .tag:before {
      content: "";
      position: absolute;
      left: -9px;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-right: 9px solid #f4eca7c2;
    }

    /* Makes the circle */

    button .tag:after {
      content: "";
      background-color: #ff851bd7;
      border-radius: 50%;
      width: 2.5px;
      height: 2.5px;

      position: absolute;
      left: -5px;
      top: 8px;
    }
  `],
  template: `
    <ng-container *ngIf="cartLineItems$ | async as cartLineItems">
      <button
        (click)="addLineItem($event, image, cartOption, cartOptions, cartLineItems, auth.account)"
        mat-raised-button
        color="accent"
        [disabled]="cartOption.disabled"
      >
        <div>
          {{ cartOption.option }}
        </div>
        <div class="tag">
          {{ cartOption.dollars }}
        </div>
      </button>
    </ng-container>
  `,
  selector: "app-cart-option",
})
export class CartOptionComponent implements OnInit {
  @Input() cartOption: CartOption
  @Input() image: Image
  @Input() cartOptions: CartOption[]
  cartLineItems$: Observable<LineItem[]>

  constructor(
    public cartService: CartService,
    public auth: AuthService,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.cartLineItems$ = this.cartService.cartLineItems$
  }

  addLineItem(event, image, cartOption, cartOptions: CartOption[], cartLineItems: LineItem[], account) {
    if (event) {
      event.preventDefault()
    }
    const newDocId = this.dataService.getNewDocId()
    const newCartLineItem: LineItem = {
      dateUpdatedString: "",
      dateUpdatedTimestamp: 0,
      id: newDocId,
      image: image,
      option: cartOption.option,
      price: cartOption.price,
      quantity: 1,
      sessionId: this.cartService.sessionId,
      singular: cartOption.singular,
      type: cartOption.type,
      status: "pending",
      uid: account.uid,
    }
    this.cartService.addLineItem(newCartLineItem, newCartLineItem, cartOptions, cartLineItems)

  }
}
