import { Component, OnInit } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { AngularFirestore } from "@angular/fire/firestore"

@Component({
  styles: [`
    mat-form-field {
      display: block;
    }
  `],
  template: `
    <div class="section-title">
      {{ title }}
    </div>
    <form [formGroup]="contactForm" [hidden]="success" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input
          matInput
          formControlName="name"
          placeholder="Enter your full name"
          autocomplete="name"
        >
        <mat-error *ngIf="name.invalid && name.touched" class="has-text-danger">
          full name, please
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="email"
          placeholder="Enter your email address"
          autocomplete="email"
        >
        <mat-error *ngIf="email.invalid && email.touched" class="has-text-danger">
          Your email does not look right
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          formControlName="phone"
          placeholder="Enter your phone number"
          autocomplete="tel-national"
        >
        <mat-hint align="end">
          phone number with area code
        </mat-hint>
        <mat-error *ngIf="phone.invalid && phone.touched" class="has-text-danger">
          full phone number, please
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <textarea
          matInput
          formControlName="message"
          placeholder="Enter your message"
          autocomplete="on"
        ></textarea>
        <mat-error *ngIf="message.invalid && message.touched" class="has-text-danger">
          is that all? please add more message, thanks
        </mat-error>
      </mat-form-field>
      <div class="center">
        <button mat-raised-button color="warn" type="submit" [disabled]="contactForm.invalid">
          Send Message
        </button>
      </div>
    </form>

    <button *ngIf="success" mat-raised-button color="warn" (click)="resetForm()">
      <div>
        Yay! We received your Message
      </div>
      <div>
        Send Another!
      </div>
    </button>
  `,
  selector: "app-contact-form",
})
export class ContactFormComponent implements OnInit {
  title = "Contact Us"
  contactForm: FormGroup

  loading = false
  success = false

  constructor(
    private fb: FormBuilder,
    private afs: AngularFirestore,
  ) {
    // comment these next 3 lines to see console warning with instructions
    // const firestore = firebase.firestore();
    // const settings = {/* your settings... */ timestampsInSnapshots: true};
    // firestore.settings(settings);
  }

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: ["", [
        Validators.required,
        Validators.minLength(2)
      ]],
      email: ["", [
        Validators.required,
        Validators.email
      ]],
      phone: ["", [
        Validators.required,
        Validators.minLength(10)
      ]],
      message: ["", [
        Validators.required,
        Validators.minLength(20)
      ]],
    })
  }

  get name() {
    return this.contactForm.get("name")
  }

  get email() {
    return this.contactForm.get("email")
  }

  get phone() {
    return this.contactForm.get("phone")
  }

  get message() {
    return this.contactForm.get("message")
  }

  resetForm() {
    this.contactForm.reset()
    this.success = false
  }

  async onSubmit() {
    // const {name, email, phone, address, message} = this.form.value;
    const date = Date()

    this.loading = true

    const formValue = this.contactForm.value
    formValue.date = date

    try {
      await this.afs.collection("messages").add(formValue)
      this.success = true
    } catch (error) {
      console.error("Error adding document: ", error)
    }

    this.loading = false
  }
}
