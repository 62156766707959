import { Component, OnInit } from "@angular/core"
import { Observable } from "rxjs"
import { LineItem } from "interfaces/cart.interface"
import { AuthService } from "services/auth.service"
import { CartService } from "services/cart.service"

@Component({
  template: `
    <h3>
      Carts
    </h3>
    <ng-container *ngIf="auth.profile$ | async">
      <ng-container *ngIf="cartLineItems$ | async as cartLineItems">
        <ng-container *ngFor="let cartLineItem of cartLineItems; let iCart = index;">
          <mat-accordion multi>
            <mat-divider></mat-divider>
            <div style="padding: 20px">
              uid:
              {{ cartLineItem.uid }}
              <mat-expansion-panel
                class="line-item"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    id:
                    {{ cartLineItem.id }}
                    {{ cartLineItem.dateUpdatedString }}
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <app-admin-cart-line-item [lineItem]="cartLineItem"></app-admin-cart-line-item>
              </mat-expansion-panel>
            </div>
          </mat-accordion>
        </ng-container>
      </ng-container>
    </ng-container>
  `,
  selector: "app-admin-carts",
})
export class AdminCartsComponent implements OnInit {
  cartLineItems$: Observable<LineItem[]>
  expanded = []

  constructor(
    public auth: AuthService,
    public cartService: CartService,
  ) {
  }

  ngOnInit() {
    this.cartLineItems$ = this.cartService.cartLineItems$
  }

  toggleExpanded(iCart: number, iLineItem: number, value: boolean) {
    if (this.expanded[iCart] === undefined) {
      this.expanded[iCart] = []
    }
    this.expanded[iCart][iLineItem] = value
  }

  isExpanded(iCart: number, iLineItem: number) {
    if (this.expanded[iCart] !== undefined) {
      return this.expanded[iCart][iLineItem]
    }
  }

  private offsetPointer(iCart: number, iLineItem: number) {
    if (this.expanded[iCart] !== undefined) {
      if (this.expanded[iCart][iLineItem + 1] !== undefined) {
        this.expanded[iCart][iLineItem] = this.expanded[iCart][iLineItem + 1]
        this.offsetPointer(iCart, iLineItem + 1)
      } else {
        this.expanded[iCart].splice(iLineItem, 1)
      }
    }
  }

}
