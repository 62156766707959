import { Directive, Output, EventEmitter, ElementRef, AfterViewInit } from "@angular/core"
import { GalleryService } from "services/gallery.service"

@Directive({
  selector: "[appBackgroundImageLoaded]"
})
export class BackgroundImageLoadedDirective implements AfterViewInit {
  @Output() imageLoaded = new EventEmitter

  constructor(
    private el: ElementRef,
    private galleryService: GalleryService,
  ) {
  }

  ngAfterViewInit() {
    const filepath = this.el.nativeElement.id.split("|")[0]
    const fileUrl = this.galleryService.getUrl(filepath)
    const img = new Image()
    // const bgStyle = getComputedStyle(this.el.nativeElement).backgroundImage;
    // img.src = fileUrl.replace(/(^url\()|(\)$|[\"\'])/g, '');
    img.src = fileUrl
    img.height = 400
    img.width = 400
    img.addEventListener("load", () => {
      this.imageLoaded.emit({ img, filepath })
    })
  }
}
