import { Component, Input, OnInit } from "@angular/core"
import { LineItem } from "interfaces/cart.interface"
import { CartService } from "services/cart.service"
import { ImagesService } from "services/images.service"
import { DomSanitizer } from "@angular/platform-browser"
import { ImageDialogComponent } from "components/dialogs/image-dialog.component"
import { MatDialog } from "@angular/material/dialog"

@Component({
  template: `
    <button
      (click)="imageDialog([lineItem.image], 0, lineItem.option)"
      class="mat-raised-button mat-accent"
      style="display: flex; justify-content: center; align-items: center; margin: 0 auto;"
    >
      <div
        [class]="getClasses('image')"
        style="margin: .5rem;"
        [style]="getStyles('image', lineItem)"
      ></div>
      <div
        style="margin: .5rem;"
      >
        {{ lineItem.option }}
      </div>
      <div
        *ngIf="context === 'user-admin'"
        style="margin: .5rem;"
      >
        {{ lineItem.image.filename }}
      </div>
    </button>
  `,
  selector: "app-order-line-item",
})
export class OrderLineItemComponent implements OnInit {
  @Input() lineItem: LineItem
  @Input() context: string

  constructor(
    public cartService: CartService,
    public dialog: MatDialog,
    private imageService: ImagesService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
  }

  getStyles(element, value) {
    const styles: string[] = []
    if (element === "image") {
      styles.push("background-image: url(" + this.imageService.getUrl(value.image.filepath, 400) + "); ")
    }

    return this.sanitizer.bypassSecurityTrustStyle(styles.join(" "))
  }

  getClasses(element) {
    const classes: string[] = []
    if (element === "image") {
      classes.push("image")
    }
    return classes.join(" ")
  }

  // mat-dialog stuff
  imageDialog(images, index, option): void {
    const data = {
      "images": images,
      "index": index,
      "digital": option
    }
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: data,
    })
    dialogRef.afterClosed().subscribe()
  }

}
