import { Injectable } from "@angular/core"
import { AuthService } from "services/auth.service"
import { GalleryService } from "services/gallery.service"

@Injectable({
  providedIn: "root",
})
export class SectionService {
  sections = {
    account: false,
    admin: false,
    cart: false,
    contact: false,
    orders: false,
    pricing: false,
    progress: false,
    search: false,
    sizer: false,
  }

  constructor(
    private auth: AuthService,
    private galleryService: GalleryService,
  ) {
  }

  public closeOthers(event, section): void {
    if (section === null) {
      this.closeAll(event)
    }
    if (section === "favorites") {
      this.galleryService.clearSelections()
    }
    if (!this.sections[section]) {
      this.closeAll(event)
      this.sections[section] = true
      this.sendGA(section)
    } else {
      this.closeAll(event)
    }
    if (event) {
      event.preventDefault()
    }
  }

  public closeAll(event, section?) {
    // if (section) {
    //   this[section] = false;
    // }
    Object.entries(this.sections).forEach(([key, value]) => {
      this.sections[key] = false
    })
    // this.orders = this.cart = this.pricing = this.progress = this.contact = this.account = this.sizer = false;
    // if (!this.auth.roles.admin) {
    // }
    if (event) {
      event.preventDefault()
    }
  }

  private sendGA(section) {
/*
    let eventLabel = "anonymous"
    if (this.auth.roles && this.auth.roles.authenticated && !this.auth.roles.superAdmin) {
      eventLabel = this.auth.user.displayName
    }
    const date = new Date()
    const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -1)
    const isoDateTimeHourMin = isoDate.slice(0, -7)
    if (!this.auth.roles || !this.auth.roles.authenticated || !this.auth.roles.superAdmin) {
      (<any>window).ga("send", {
        hitType: "event",
        eventCategory: section + " section",
        eventAction: isoDateTimeHourMin,
        eventLabel: eventLabel,
      })
    }
*/
  }
}
