import { Component, HostListener, Inject, OnInit } from "@angular/core"
import { GalleryService } from "services/gallery.service"
import { SectionService } from "services/section.service"
import { DOCUMENT } from "@angular/common"
import { AuthService } from "services/auth.service"

@Component({
  template: `
    <div
      style="display: flex; flex-direction: row; flex-wrap: wrap; align-content: flex-start; height: 100vh;"
      [style.background]="backgroundImage"
      id="gallery"
    >

      <div [ngClass]="getClasses('scroller', 1000)" [ngxScrollTo]="'gallery'">
        <i class="material-icons md-96 md-light">
          keyboard_arrow_up
        </i>
      </div>

      <div
        fxFlex="100" fxLayout="row" fxLayoutAlign="center start" 
        class="center"
        style="margin: 5px; color: #de6e2f; font-size: 48px;"
      >
        <div
          style="padding: 5px; background: rgba(33, 33, 33, .6); width: 64px; height: 64px;"
        >
          <i
            class="material-icons md-48 md-light"
          >
            favorite_border
          </i>

        </div>
        <div style="padding: 5px; background: rgba(33, 33, 33, .6);">
          Favorites
        </div>
      </div>

      <div fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="center center" class="flex-wrap gallery-icons center">
          <div class="search-results">
            <button mat-raised-button (click)="sectionService.closeOthers(null, 'results')">
              {{ auth.favoritesCount }}
              favorites
            </button>
          </div>

          <!--
                <div (click)="sectionService.closeOthers(null, 'search')" class="clickable gallery-icon">
                  <div>
                    <i class="material-icons md-light md-36">
                      search
                    </i>
                  </div>
                </div>
          -->

          <!--
                <div (click)="gallery.resetSearch()" class="clickable gallery-icon">
                  <div>
                    <i class="material-icons md-light md-36">
                      cancel
                    </i>
                  </div>
                </div>
          -->

          <!--
                <div (click)="sectionService.closeOthers(null, 'sizer')" class="clickable gallery-icon">
                  <div>
                    <i class="material-icons md-light md-36">
                      photo_library
                    </i>
                  </div>
                </div>
          -->
        </div>
      </div>

      <div fxFlex="100" *ngFor="let selection of galleryService.selections; index as index;">
        <app-gallery-navigator [selection]="selection" [level]="" [type]="'favorites'"></app-gallery-navigator>
      </div>

      <!--
        <section [ngClass]="{'sizer': true, 'expand': sectionService.sections.sizer, 'collapsible': true}">
          <mat-card class="result center">
            <h2>Preview Sizer</h2>
            <mat-card-content>
              <mat-slider
                color="primary"
                class="image-size"
                [disabled]="false"
                [invert]="false"
                [max]="400"
                [min]="50"
                [step]="50"
                [thumbLabel]="true"
                [tickInterval]="50"
                [(ngModel)]="galleryService.sizer"
                [vertical]="false">
              </mat-slider>
            </mat-card-content>
          </mat-card>
        </section>
      -->

      <!--
        <section fxFlex="100" [ngClass]="{'search': true, 'center': true, 'expand': sectionService.sections.search, 'collapsible': true}">
          <div class="tags-input">
            <input [(ngModel)]="galleryService.newTag" (ngModelChange)="galleryService.updateSearch()" placeholder="Filter by Racer Number">
          </div>
        </section>
      -->

      <div fxFlex="100">
        <app-gallery-image [type]="'favorites'"></app-gallery-image>
      </div>

    </div>
  `,
  selector: "app-favorites",
})
export class FavoritesComponent implements OnInit {
  offset: any

  backgroundImage = "url('/assets/images/banner-image.jpg') center/cover no-repeat;"

  constructor(
    public auth: AuthService,
    public galleryService: GalleryService,
    public sectionService: SectionService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.galleryService.nodes = []
  }

  ngOnInit() {
    this.galleryService.clearSelections()
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.offset = document.documentElement.scrollTop
  }

  public getClasses(element, value) {
    const classes: string[] = []
    if (element === "scroller") {
      classes.push(element)
      if (this.offset > value) {
        classes.push("show-scroller")
      }
    }
    return classes.join(" ")
  }

}



