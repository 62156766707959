import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { ImageDialogComponent } from "components/dialogs/image-dialog.component"
import { GalleryService } from "services/gallery.service"
import { SectionService } from "services/section.service"
import { Subscription } from "rxjs"
import { FavoritesService } from "services/favorites.service"
import { AuthService } from "services/auth.service"
import { Image } from "interfaces/image.interface"

@Component({
  styles: [`
    .gallery-image {
      background-size: cover;
      background-position: 50% 25%;
      opacity: 1;
      transition: opacity 1s ease-out, transform 1s ease-out, margin .2s ease-out, background-position 1s ease-out;
    }
    .gallery-image:hover {
      transform: rotate(5deg) scale(2);
    }
  `],
  template: `
    <div
      infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollThrottle]="50"
      style="display: flex; flex-direction: column; gap: 100px; align-items: center; padding: 20px"
      (scrolled)="galleryService.addMoreImages(24)"
    >

      <div
        style="display: flex; flex-wrap: wrap; align-content: stretch; justify-content: center; align-items: stretch; gap: 10px"
      >

        <ng-container *ngIf="auth.favorites$ | async as favorites">

          <ng-container *ngIf="type === 'gallery'">
            <ng-container
              *ngFor="let image of galleryService.images; index as index"
            >
              <div
                *ngIf="index < galleryService.scrollIndex"
                style="display: flex; flex-direction: column; align-items: center"
              >
                <div
                  style="text-shadow: 0 0 3px white; border-radius: 10px; overflow: hidden; position: relative;"
                >
                  <ng-container *ngIf="auth.profile$ | async as profile">
                    <div
                      *ngIf="profile.roles.authenticated"
                      style="background: #0a0a0a; border-bottom-right-radius: 75%; height: 30px; width: 30px; position: absolute; z-index: 999;"
                    >
                      <i
                        *ngIf="favorites[image.filename]"
                        style="font-size: medium; padding: 5px; color: #ff671b;"
                        class="material-icons"
                        matTooltip="Remove from Favorites"
                        matTooltipClass="tooltip"
                        (click)="favoritesService.toggleFavorite(profile.user.email, favorites, image.filepath, false)"
                      >
                        favorite
                      </i>
                      <i
                        *ngIf="!favorites[image.filename]"
                        style="font-size: medium; padding: 5px; color: #ff671b;"
                        class="material-icons"
                        matTooltip="Add to Favorites"
                        matTooltipClass="tooltip"
                        (click)="favoritesService.toggleFavorite(profile.user.email, favorites, image.filepath, true)"
                      >
                        favorite_border
                      </i>
                    </div>
                  </ng-container>
                  <div
                    appBackgroundImageLoaded
                    class="gallery-image"
                    [id]="image.filepath"
                    [style.width.px]="galleryService.sizer"
                    [style.height.px]="galleryService.sizer"
                    (click)="clickImage(galleryService.images, index, type)"
                    (imageLoaded)="galleryService.loaded($event, index)"
                  ></div>
                </div>
                <ng-container *ngIf="auth.modes.edit">
                  <input
                    *ngFor="let tag of image.tags"
                    style="width: 50px; text-align: center"
                    [value]="tag"
                  >
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="type === 'favorites'">
            <ng-container *ngFor="let favorite of favorites | keyvalue; index as index">
              <div
                *ngIf="index < galleryService.scrollIndex"
                class="gallery-image-wrapper"
              >
                <ng-container *ngIf="auth.profile$ | async as profile">
                  <div
                    *ngIf="profile.roles.authenticated"
                    class="favorites-wrapper"
                  >
                    <i
                      class="material-icons md-36 favorite is-favorite"
                      matTooltip="Remove from Favorites"
                      matTooltipClass="tooltip"
                      (click)="favoritesService.toggleFavorite(profile.user.email, favorites, favorite.value['filepath'], false)"
                    >
                      favorite
                    </i>
                  </div>
                </ng-container>
                <div
                  appBackgroundImageLoaded
                  class="gallery-image"
                  [id]="favorite.value['filepath']"
                  (click)="clickImage(galleryService.images, index, type)"
                  (imageLoaded)="galleryService.loaded($event, index)"
                ></div>
              </div>
            </ng-container>
          </ng-container>

        </ng-container>

      </div>

      <button
        mat-raised-button
        (click)="clickMore()"
        *ngIf="galleryService.scrollIndex < galleryService.images.length - 1"
      >
        load more photos
      </button>

    </div>
  `,
  selector: "app-gallery-image",
})
export class GalleryImageComponent implements OnInit, OnDestroy {
  @Input() type
  subscription: { [key: string]: Subscription } = {}

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    public favoritesService: FavoritesService,
    public galleryService: GalleryService,
    public sectionService: SectionService,
  ) {
  }

  ngOnInit() {
    this.galleryService.scrollIndex = 24
    this.galleryService.sizer = this.galleryService.sizer || 200
    this.subscription["routeImageIndex"] = this.galleryService.routeImageIndex
      .subscribe(index => {
        // duplicate condition, for reference only, !== null is probably enough
        if (index !== null && (index || index === 0)) {
          this.showImage(this.galleryService.images, index, this.type)
        }
      })
  }

  ngOnDestroy(): void {
    for (const key in this.subscription) {
      this.subscription[key].unsubscribe()
    }
  }

  clickImage(images: Image[], index: number, type: string) {
    if (this.type === "gallery") {
      this.galleryService.setRoute(images[index].filepath)
    }
    this.showImage(images, index, type)
  }

  showImage(images: Image[], index: number, type: string) {
    this.galleryService.addMoreImages(24)
    this.imageDialog(images, index, type)
  }

  clickMore() {
    this.galleryService.addMoreImages(24)
  }

  // mat-dialog stuff
  private imageDialog(images: Image[], index: number, type: string): void {
    const data = {
      "images": images,
      "index": index,
    }
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: data,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (type === "gallery") {
        const route = images[index].filepath.split("/").slice(0, -1).join("/")
        this.galleryService.setRoute(route)
      }
      if (res) {
        if (res.next) {
          index++
          if (index === images.length) {
            index = 0
          }
          this.imageDialog(images, index, type)
        }
        if (res.previous) {
          index--
          if (index === -1) {
            index = images.length - 1
          }
          this.imageDialog(images, index, type)
        }
        if (res.join || res.update || res.login) {
          setTimeout(() => {
            this.join()
          }, 500)
        }
        if (res.goToCart) {
          // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          setTimeout(function () {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }, 500)
        }
      }
    })
  }

  private join() {
    let timeout = 3000
    if (window.scrollY < timeout * 3) {
      timeout = window.scrollY / 3
    }
    setTimeout(() => {
      this.sectionService.sections.account = true
    }, timeout)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

}
