import { Component, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"

@Component({
  template: `
  <div style="display: flex; flex-direction: column; align-items: center">
  <h1 mat-dialog-title>
    Confirm
  </h1>
  <div>
    <input [(ngModel)]="label"/>
    <button mat-button [mat-dialog-close]="{'applyLabel': true, 'label': label}">
      Apply Label
    </button>
    <button mat-button [mat-dialog-close]="{'applyLabel': true, 'label': false}">
      Reset Label
    </button>
    <button mat-button (click)="onNoClick()">
      Cancel
    </button>
    <button mat-button [mat-dialog-close]="{'delete': true}">
      Delete
    </button>
    <button mat-button [mat-dialog-close]="{'searchable': true}">
      allow search
    </button>
    <button mat-button [mat-dialog-close]="{'searchable': false}">
      disallow search
    </button>
    <button mat-button [mat-dialog-close]="{'public': true}">
      Public
    </button>
    <button mat-button [mat-dialog-close]="{'public': false}">
      Private
    </button>
  </div>
</div>
`,
  selector: "app-confirm-dialog",
})
export class ConfirmDialogComponent {
  label: string

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

}
