import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root"
})
export class EmailService {
  year: number
  series: string
  race: string

  constructor() {
  }

}
