import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import firebase from "firebase/app"
import "firebase/firestore"
import { ActionsService } from "services/actions.service"
import Timestamp = firebase.firestore.Timestamp

@Injectable({
  providedIn: "root"
})
export class DataService {
  constructor(
    private afs: AngularFirestore,
    private actionsService: ActionsService
  ) {
  }

  insertData(collection: string, docId: string, data: any) {
    this.actionsService.saveActionData({
      action: "insertData",
      collection,
      created: Timestamp.now(),
      data,
      docId,
      uid: ""
    })
    return this.afs
      .collection(collection)
      .doc(docId)
      .set({
        ...data,
        dateUpdated: Timestamp.now()
      }, { merge: true })
  }

  addToArray(collection, docId, arrayName, data) {
    console.log("addToArray")
    return this.afs
      .collection(collection)
      .doc(docId)
      .update({ [arrayName]: firebase.firestore.FieldValue.arrayUnion(data) })
  }

  removeFromArray(collection, docId, arrayName, data) {
    console.log("removeFromArray")
    return this.afs
      .collection(collection)
      .doc(docId)
      .update({ [arrayName]: firebase.firestore.FieldValue.arrayRemove(data) })
  }

  getNewDocId(): string {
    return this.afs.createId()
  }

  deleteDoc(collection: string, docId: string) {
    this.actionsService.saveActionData({
      action: "deleteDoc",
      collection: collection,
      created: Timestamp.now(),
      data: "",
      docId: docId,
      uid: ""
    })
    return this.afs
      .collection(collection)
      .doc(docId)
      .delete()
  }

}
