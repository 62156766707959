import { Component, HostListener, Inject, OnInit } from "@angular/core"
import { GalleryService } from "services/gallery.service"
import { SectionService } from "services/section.service"
import { DOCUMENT } from "@angular/common"
import { AuthService } from "services/auth.service"

@Component({
  styles: [`
    .navigator-title > div {
      padding: 5px;
      background: rgba(33, 33, 33, .6);
    }
  `],
  template: `
    <div
      fxLayout="row"
      fxLayoutAlign="center start"
      id="gallery"
      class="flex-wrap"
      style="height: 100vh"
      [style.background]="backgroundStyle"
    >

      <div [ngClass]="getClasses('scroller', 1000)" [ngxScrollTo]="'gallery'" style="position: fixed; z-index: 1; bottom: 0">
        <i class="material-icons md-96 md-light">
          keyboard_arrow_up
        </i>
      </div>

      <div
        class="center navigator-title"
        style="margin: 5px; color: #de6e2f; font-size: 48px;"
        fxFlex="100" fxLayout="row" fxLayoutAlign="center start"
      >
        <div style="width: 64px; height: 64px">
          <img src="/assets/images/events-icon.png">
        </div>
        <div>
          Events
        </div>
      </div>

      <div fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="center center" class="flex-wrap gallery-icons center">
          <div class="search-results">
            <button
              mat-raised-button *ngIf="galleryService.images.length"
              (click)="sectionService.closeOthers(null, 'results')"
            >
              {{ galleryService.images.length }}
              photos
            </button>
            <button mat-raised-button *ngIf="!galleryService.images.length" (click)="galleryService.resetSearch()">
              0 Results
            </button>
          </div>

          <div
            style="padding: 5px 20px"
          >
            <input
              [(ngModel)]="galleryService.newTag"
              (ngModelChange)="galleryService.updateSearch()"
              placeholder="filter"
              style="font-size: 20px; width: 75px; text-align: center;"
            >
          </div>

          <div *ngIf="auth.modes.edit">
            <mat-card style="padding: 0">
              <mat-card-content>
                <div style="display: inline-block; padding: 8px">
                  Image Size
                </div>
                <mat-slider
                  color="primary"
                  class="image-size"
                  [disabled]="false"
                  [invert]="false"
                  [max]="400"
                  [min]="100"
                  [step]="50"
                  [thumbLabel]="true"
                  [tickInterval]="50"
                  [(ngModel)]="galleryService.sizer"
                  [vertical]="false"
                >
                </mat-slider>
              </mat-card-content>
            </mat-card>
          </div>

        </div>
      </div>

      <div fxFlex="100" *ngFor="let selection of galleryService.selections; index as index;">
        <app-gallery-navigator [selection]="selection" [level]="index" [type]="'gallery'"></app-gallery-navigator>
      </div>

      <div fxFlex="100">
        <app-gallery-image [type]="'gallery'"></app-gallery-image>
      </div>

    </div>
  `,
  selector: "app-gallery",
})
export class GalleryComponent implements OnInit {
  offset: any

  backgroundStyle = "url('/assets/images/banner-image.jpg') center/cover no-repeat"

  constructor(
    public auth: AuthService,
    public galleryService: GalleryService,
    public sectionService: SectionService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    /**
     * TODO: Service???
     */
    this.galleryService.nodes = []
  }

  ngOnInit() {
    /**
     * TODO: make this an observable of some kind
     */
    this.galleryService.selections = []
    this.galleryService.selections[0] = [0]
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.offset = document.documentElement.scrollTop
  }

  public getClasses(element, value) {
    const classes: string[] = []
    if (element === "scroller") {
      classes.push(element)
      if (this.offset > value) {
        classes.push("show-scroller")
      }
    }
    return classes.join(" ")
  }

}



