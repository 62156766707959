import { Component, Input, OnInit } from "@angular/core"
import { CartOption, LineItem } from "interfaces/cart.interface"
import { CartService } from "services/cart.service"
import { ImagesService } from "services/images.service"
import { DomSanitizer } from "@angular/platform-browser"
import { ImageDialogComponent } from "components/dialogs/image-dialog.component"
import { MatDialog } from "@angular/material/dialog"
import { AngularFirestore } from "@angular/fire/firestore"
import { Observable } from "rxjs"

@Component({
  styles: [`
    .line-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
      margin: 0 auto;
    }

    .line-item .image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-position: center center;
      background-size: cover;
      align-self: center;
    }

    .line-item div {
      margin: 0.5rem;
    }

    .line-item .form {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 50%;
    }

    .line-item .form .dropdown {
      width: 75%;
    }

    .line-item .form .quantity {
      width: 20%;
    }
  `],
  template: `
    <ng-container *ngIf="cartOptions$ | async as cartOptions">

      <div class="line-item" *ngIf="cartLineItem.type === 'sales tax'">
        Sales Tax
        {{ cartLineItem.price * cartLineItem.quantity / 100 | currency }}
      </div>

      <div class="line-item" *ngIf="cartLineItem.type === 'shipping'">
        Shipping
        {{ cartLineItem.price / 100 | currency }}
      </div>

      <div class="line-item" *ngIf="cartLineItem.type !== 'sales tax' && cartLineItem.type !== 'shipping'">
        <div
          appBackgroundImageLoaded
          (click)="imageDialog([cartLineItem.image], 0, cartLineItem.type)"
          class="image clickable"
          [id]="cartLineItem.image.filepath + '|' + cartLineItem.id"
          (imageLoaded)="loaded($event)"
        ></div>
        <div class="form">
          <mat-form-field class="dropdown">
            <mat-label>Photo Options</mat-label>
            <mat-select
              [(ngModel)]="ngModelCartLineItem.option"
              name="cartOptionsDescription"
              (ngModelChange)="cartService.addLineItem(ngModelCartLineItem, cartLineItem, cartOptions, cartLineItems)"
            >
              <mat-option
                *ngFor="let item of cartOptions"
                [value]="item.option"
                [disabled]="item.disabled"
              >
                {{ item.option }} - {{ item.price / 100 | currency }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="quantity">
            <input
              [(ngModel)]="cartLineItem.quantity"
              name="lineItemQuantity"
              matInput
              type="number"
              value=1
              placeholder="Quantity"
              min=0
              (change)="cartService.lineItemQuantityChange(cartLineItem)"
            >
          </mat-form-field>
        </div>
        <!-- <div (click)="imageDialog([lineItem.image], 0)">
          Qty: 1
        </div> -->
        <div (click)="imageDialog([cartLineItem.image], 0, cartLineItem.type)" class="clickable">
          {{ cartLineItem.price * cartLineItem.quantity / 100 | currency }}
        </div>
        <i
          (click)="cartService.removeLineItem($event, cartLineItem)"
          class="material-icons clickable"
          matTooltip="Delete this Line-Item"
          matTooltipClass="tooltip"
        >
          remove_circle_outline
        </i>
      </div>

    </ng-container>
  `,
  selector: "app-cart-line-item",
})
export class CartLineItemComponent implements OnInit {
  @Input() cartLineItem: LineItem
  @Input() cartLineItems: LineItem[]
  ngModelCartLineItem: LineItem
  cartOptions$: Observable<CartOption[]>

  constructor(
    private afs: AngularFirestore,
    public cartService: CartService,
    public imageService: ImagesService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.ngModelCartLineItem = Object.create(this.cartLineItem)
    this.cartOptions$ = this.cartService.cartOptions$
    // .pipe(
    //   tap(cartOptions => {
    //     cartOptions.map(cartOption => {
    //       if (cartOption.type === 'digital') {
    //         this.log(this.cartLineItems);
    //         this.log(this.cartLineItem.image.filename);
    //         const match = this.cartLineItems
    //           .filter(e => e.option === cartOption.option && e.image.filename === this.cartLineItem.image.filename);
    //         this.log(match);
    //         cartOption.disabled = !!match.length;
    //       }
    //     });
    //   })
    // );
  }

  loaded(event) {
    const element = document.getElementById(event.filepath + "|" + this.cartLineItem.id)
    element.setAttribute("height", "80px")
    element.setAttribute("width", "80px")
    element.setAttribute("style", "background-image: url(\"" + event.img.src + "\"); height: 80px; width: 80px;")
  }

  getStyles(element, value) {
    const styles: string[] = []
    if (element === "image") {
      styles.push("background-image: url(" + this.imageService.getUrl(value.image.filepath, 400) + "); ")
    }
    return this.sanitizer.bypassSecurityTrustStyle(styles.join(" "))
  }

  getClasses(element) {
    const classes: string[] = []
    if (element === "image") {
      classes.push("image")
      classes.push("clickable")
    }
    return classes.join(" ")
  }

  // mat-dialog stuff
  imageDialog(images, index, type): void {
    if (type === "sales tax") {
      return
    }
    const data = {
      images: images,
      index: index,
    }
    const dialogRef = this.dialog.open(ImageDialogComponent, {
      data: data,
    })
    dialogRef.afterClosed().subscribe()
  }

  private log(message) {
    console.log(message)
  }
}
