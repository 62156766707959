import { Component, Input, OnInit } from "@angular/core"
import { CartService } from "services/cart.service"
import { SectionService } from "services/section.service"
import { Image } from "interfaces/image.interface"
import { Observable } from "rxjs"
import { LineItem } from "interfaces/cart.interface"
import { tap } from "rxjs/operators"

@Component({
  template: `
    <ng-container *ngIf="cartLineItems$ | async">
      <a
        *ngIf="type === 'header' || type === 'dialog'"
        (click)="iconClick($event, 'cart', type)"
        class="clickable"
        matTooltip="{{cartQuantity}} Cart {{pluralize('Item', cartQuantity)}}"
        matTooltipClass="tooltip"
        href="/cart" style="position: relative; display: block;"
      >
        <i
          *ngIf="type === 'header'"
          class="material-icons md-36 md-light cart is-cart"
        >
          shopping_cart
        </i>
        <i
          *ngIf="type === 'dialog'"
          class="material-icons md-36 md-dark cart is-cart"
        >
          shopping_cart
        </i>
        <div
          *ngIf="cartQuantity"
          style="position: absolute; left: 50%; transform: translateX(-50%); top: 6px; font-size: 12px; font-weight: 600; color: #0a0a0a"
        >
          {{ cartQuantity }}
        </div>
      </a>
    </ng-container>
  `,
  selector: "app-cart-icon",
})
export class CartIconComponent implements OnInit {
  @Input() image: Image
  @Input() type: string
  cartLineItems$: Observable<LineItem[]>
  cartQuantity: number

  constructor(
    public cartService: CartService,
    public sectionService: SectionService,
  ) {
  }

  ngOnInit() {
    this.cartLineItems$ = this.cartService.cartLineItems$
      .pipe(
        tap(cartLineItems => {
          let cartQuantity = 0
          cartLineItems.forEach(item => {
            if (item.type === "digital" || item.type === "small print" || item.type === "large print" || item.type === "collage") {
              this.log(item.type)
              this.log(item.quantity)
              cartQuantity = cartQuantity + item.quantity
            }
          })
          this.cartQuantity = cartQuantity
          // console.log(this.cartQuantity);
        })
      )
  }

  iconClick(event, section, type) {
    if (event) {
      event.preventDefault()
    }
    if (type === "dialog") {
      this.sectionService.closeAll("")
      this.sectionService.closeOthers("", section)
      // console.log('CartIcon:iconClick()');
    }
    if (type === "header") {
      this.sectionService.closeOthers("", section)
    }


  }

  /*
    addCartItem(event, data) {
      this.sectionService.cart = true;
      this.cartService.addCartItem(event, data);
    }
  */

  pluralize(word: string, quantity: number): string {
    if (quantity !== 1) {
      return word + "s"
    } else {
      return word
    }
  }

  private log(message) {
    // console.log(message);
  }

}
