import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root"
})
export class ImagesService {

  constructor() {
  }

  getUrl(path: string, size: number): string {
    let imagePath: string
    if (size <= 400) {
      imagePath = "public/" + path
    } else {
      imagePath = "public-800/" + path
    }
    return "https://storage.googleapis.com/photos-public.xtrphoto.com/" +
      imagePath
        .split("/").join("%2F")
        .split(" ").join("%20")
  }

}
