import { Injectable } from "@angular/core"

@Injectable({
  providedIn: "root"
})
export class LogService {

  constructor() {
  }

  log(type, message) {
    // const enabled = true;
    const enabled = false
    if (enabled) {
      if (type === "table") {
        console.table(message)
      } else {
        console.log(message)
      }
    }
  }

}
