import { Component } from "@angular/core"

@Component({
  template: `
    <div fxLayout="row" fxLayoutAlign="center" class="flex-wrap">
      <div fxFlex="100" fxFlex.md="50" fxFlex.gt-md="33" class="membership">
        <div>
          Members
        </div>
        <mat-card style="margin: 10px">
          <div>
            Get affordable high quality photographs
          </div>
          <div>
            to preserve your memories.
          </div>
          <div>
            Buy a Membership
          </div>
          <div>
            View • Save • Download 800px sizes
          </div>
          <div>
            view all at 800px for yr and past yrs
          </div>
          <div>
            $50 for current year
          </div>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.md="50" fxFlex.gt-md="33" class="digital">
        <div>
          Digitals
        </div>
        <mat-card style="margin: 10px">
          <div>
            view, download, and print
          </div>
          <div>
            all images at 400px
          </div>
          <div>
            all images at 800px with membership
          </div>
          <div>
            $8.50 to purchase one 1600px
          </div>
          <div>
            $19.00 for high res 3200px picture
          </div>
        </mat-card>
      </div>
      <div fxFlex="100" fxFlex.md="50" fxFlex.gt-md="33" class="print">
        <div>
          Prints
        </div>
        <mat-card style="margin: 10px">
          <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="1rem">
            <div fxLayout="column">
              <div>
                Photo Prints
              </div>
              <div>
                $7.50 4x6 photo
              </div>
              <div>
                $9.50 5x7 photo
              </div>
              <div>
                $15 8x10 photo
              </div>
            </div>
            <div fxLayout="column">
              <div>
                Poster Prints
              </div>
              <div>
                $25 13x19 poster
              </div>
              <div>
                $35 17x22 poster
              </div>
            </div>
          </div>
          <div>
            Photo Collage Prints
          </div>
          <div>
            $60 13x19 collage (choose 9 photos)
          </div>
          <div>
            $70 17x22 collage (choose 9 photos)
          </div>
          <div>
            $75 - $100 team or family collages
          </div>
          <div>
            (contain more than 9 photos)
          </div>
          <div>
            Photo Collage Ordering
          </div>
          <div>
            A Photo Collage can contain 9 pictures and is printed on 13” x 19” or 17” x 22” photo paper.
          </div>
          <div>
            Pick out your own pictures or let me choose.
          </div>
          <div>
            I will send a Proof for your approval before I print.
          </div>
          <div>
            Email me your selection or racer number.
          </div>
        </mat-card>
      </div>
    </div>
  `,
  selector: "app-pricing",
})
export class PricingComponent {
}
