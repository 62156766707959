import { DatePipe } from "@angular/common"
import { Component, OnInit } from "@angular/core"
import { AngularFireStorage, BUCKET } from "@angular/fire/storage"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { MatSnackBar } from "@angular/material/snack-bar"
import { EmailService } from "services/email.service"

@Component({
  providers: [
    DatePipe,
    { provide: BUCKET, useValue: "email-race-spreadsheets" }
  ],
  template: `
    <mat-vertical-stepper [linear]="true" #stepper>
      <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel>
            Select Subseries for these emails
          </ng-template>
          <div
            style="display: flex; flex-wrap: nowrap; flex-direction: row; justify-content: space-around; align-items: center;"
          >
            <span>
              <mat-form-field>
                <mat-label>
                  Race Year
                </mat-label>
                <mat-select formControlName="yearCtrl" required>
                  <mat-option
                    *ngFor="let year of raceYears"
                    [value]="selectedYear"
                  >
                    {{ year }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>
            <mat-icon>
              arrow_forward
            </mat-icon>
            <span>
          <mat-form-field>
            <mat-label>
              Race Series
            </mat-label>
            <mat-select formControlName="seriesCtrl" required>
              <mat-option
                *ngFor="let series of raceSeries"
                [value]="selectedSeries"
              >
                {{ series }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
            <mat-icon>
              arrow_forward
            </mat-icon>
            <span>
          <mat-form-field>
            <mat-label>
              Race
            </mat-label>
            <mat-select formControlName="raceCtrl" required>
              <mat-option
                *ngFor="let race of races"
                [value]="selectedRace"
              >
                {{ race }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </span>
          </div>
          <div>
            <button mat-button matStepperNext>
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel>
            Date of Race
          </ng-template>
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="Race Date"
              formControlName="dateCtrl"
              required
            >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div>
            <button mat-button matStepperPrevious>
              Back
            </button>
            <button mat-button matStepperNext>
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
          <ng-template matStepLabel>
            Upload File
          </ng-template>
          <!--
                <mat-file-upload
                  labelText="Select a .csv file to upload. Clicking 'Upload' finalizes the process and sends emails."
                  allowMultipleFiles="false"
                  [showUploadButton]="!hideUploadButton"
                  (uploadClicked)="onUploadClicked($event)"
                >
                </mat-file-upload>
          -->
          <ng-container *ngIf="hideUploadButton">
            <p>File uploaded!</p>
          </ng-container>
          <div>
            <button mat-button matStepperPrevious>
              Back
            </button>
            <button mat-button matStepperNext>
              Next
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>
          Done
        </ng-template>
        You're all set!
        <div>
          <button mat-button matStepperPrevious>
            Back
          </button>
          <button mat-button (click)="stepper.reset()">
            Reset
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  `,
  selector: "app-admin-mass-email",
})
export class AdminMassEmailComponent implements OnInit {
  selectedYear: number
  selectedSeries: string
  selectedRace: string
  firstFormGroup: FormGroup
  secondFormGroup: FormGroup
  thirdFormGroup: FormGroup
  raceYears: number[]

  raceSeries: string[]
  races: string[]
  // fileToUpload: File;
  // selectedDateControl = new FormControl(new Date());
  hideUploadButton = false

  // selectedDate: any;

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService,
    private datePipe: DatePipe,
    private storage: AngularFireStorage,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.selectedYear = this.emailService.year
    this.selectedSeries = this.emailService.series
    this.selectedRace = this.emailService.race
    this.firstFormGroup = this.formBuilder
      .group({
        yearCtrl: ["", Validators.required],
        seriesCtrl: ["", Validators.required],
        raceCtrl: ["", Validators.required],
      })
    this.secondFormGroup = this.formBuilder
      .group({
        dateCtrl: ["", Validators.required]
      })
    this.thirdFormGroup = this.formBuilder
      .group({
        uploadCtrl: ["", Validators.required]
      })
    this.raceYears = [this.selectedYear || null]

    this.raceSeries = [this.selectedSeries || null]

    this.races = [this.selectedRace || null]

    if (this.selectedYear) {
      this.firstFormGroup
        .get("yearCtrl")
        .setValue(this.selectedYear)
    }
    if (this.selectedSeries) {
      this.firstFormGroup
        .get("seriesCtrl")
        .setValue(this.selectedSeries)
    }
    if (this.selectedRace) {
      this.firstFormGroup
        .get("raceCtrl")
        .setValue(this.selectedRace)
    }
  }

  onUploadClicked(event: FileList) {
    if (!event[0].name.endsWith(".csv")) {
      this.snackBar.open("The file upload needs to be a .csv file. Please change the file type before continuing.", "Got it")
      return
    }
    this.hideUploadButton = true
    this.thirdFormGroup
      .get("uploadCtrl")
      .setValue(event[0].name)
    // const date = this.datePipe.transform(this.selectedDateControl.value, 'MM-dd-yyyy');
    const series = this.selectedSeries
      .replace(/ /g, "-")
    const raceNameParts = this.selectedRace
      .split(" ")
    let raceName = ""
    raceNameParts.forEach(part => {
      if (isNaN(part as any)) {
        raceName = raceName === "" ? part : raceName + "-" + part
      }
    })
    const filePath = this.selectedYear + "_" + series + "_" + raceName + ".csv"
    const file = event[0]
    this.storage
      .ref(filePath)
      .put(file)
      .catch(err => console.log(err))
  }

}
