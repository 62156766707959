import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout"
import { Component, OnInit } from "@angular/core"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"

@Component({
  template: `
    <mat-sidenav-container style="height: 900px; overflow: auto; z-index: 0;">
      <mat-sidenav
        #drawer
        style="width: 200px;"
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false"
        [ngClass]="{hidden: (isHandset$ | async)}"
        [fixedInViewport]="false"
      >
        <mat-toolbar color="accent">
          Menu
        </mat-toolbar>
        <mat-nav-list>
          <a
            mat-list-item
            routerLink="carts"
            routerLinkActive="active-link"
          >
            Carts
          </a>
          <a
            mat-list-item
            routerLink="completed-orders"
            routerLinkActive="active-link"
          >
            Order History
          </a>
          <a
            mat-list-item
            routerLink="post-race-email"
            routerLinkActive="active-link"
          >
            Post-Race Email
          </a>
          <a
            mat-list-item
            routerLink="user"
            routerLinkActive="active-link"
          >
            User And Order Info
          </a>
          <mat-divider></mat-divider>
          <a
            mat-list-item
            (click)="drawer.toggle()"
          >
            Close Sidebar
          </a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-toolbar color="accent">
          <button
            type="button"
            aria-label="Toggle sidenav"
            mat-icon-button
            (click)="drawer.toggle()"
            *ngIf="!drawer.opened"
          >
            <mat-icon aria-label="Side nav toggle icon">
              menu
            </mat-icon>
          </button>
          Admin Toolbar
        </mat-toolbar>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  `,
  selector: "app-admin-nav",
})
export class AdminNavComponent implements OnInit {

  isHandset$: Observable<boolean>

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    this.isHandset$ = this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(
        map(result => result.matches))
  }

  ngOnInit() {
  }

}
