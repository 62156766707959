import { Component } from "@angular/core"
import { SectionService } from "services/section.service"
import { AuthService } from "services/auth.service"

@Component({
  styles: [`
    .header {
      background-color: #0a0a0a;
      padding: 10px 30px;
      position: relative;
      overflow: hidden;
    }

    .header > div {
      padding: 0;
    }

    .header .header-icons {
      height: 100%;
      top: 3px;
      right: 20px;
    }

    .header .header-icons > div {
        padding: 0 20px;
      }


    .header .logo {
      min-width: 130px;
      z-index: 1;
      float: left;
    }

    /*@media (min-width: 700px) {*/
    /*  overflow: visible;*/
    
    /*  .logo {*/
    /*    position: absolute;*/
    /*    top: 0;*/
    /*    left: 20px;*/
    /*  }*/
    /*}*/

    .facebook-icon img {
      width: 30px;
      position: absolute;
      right: 20px;
      top: 15px;
      z-index: 1;
    }

    .header-search input {
      font-size: 18px;
      width: 55px;
    }

    .user-photo {
      width: 36px;
    }

    .user-photo img {
      width: 100%;
      border-radius: 15px;
    }
  `],
  template: `
    <div
      *ngIf="auth.profile$ | async as profile"
      class="header" id="header"
    >
      <div class="facebook-icon">
        <a href="https://www.facebook.com/XtrPhotocom-132299526789766" target="_blank" rel="noopener">
          <img src="assets/images/fb-white-120px.png?v=1" alt="link to facebook"/>
        </a>
      </div>
      <a
        class="logo"
        (click)="sectionService.closeOthers($event, null)"
        routerLink="/"
        href="/"
      >
        <img src="assets/images/logo.png" alt="logo"/>
      </a>
      <div fxHide fxShow.gt-sm style="min-width: 130px;"></div>
      <div fxLayout="row" fxLayoutAlign="center center" class="header-icons flex-wrap">
        <a
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
          (click)="sectionService.closeOthers($event, 'pricing')"
          class="clickable"
          href="/pricing"
        >
          <div>
            <i class="material-icons md-light md-36">
              attach_money
            </i>
          </div>
          <div style="color: #f3f3f3; font-size: 20px">
            Pricing
          </div>
        </a>
        <a
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
          (click)="sectionService.closeOthers($event, 'contact')"
          class="clickable"
          href="/contact"
        >
          <div>
            <i class="material-icons md-light md-36">
              mail
            </i>
          </div>
          <div style="color: #f3f3f3; font-size: 20px">
            Contact
          </div>
        </a>

        <a
          *ngIf="profile.user.displayName"
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
          (click)="sectionService.closeOthers($event, 'account')"
          class="clickable"
          href="/account"
          matTooltip="Your Account"
          matTooltipClass="tooltip"
        >
          <div class="user-photo">
            <img [src]="profile.user.photoURL">
          </div>
          <div style="color: #f3f3f3; font-size: 20px">
            {{ profile.user.displayName }}
          </div>
        </a>
        <a
          *ngIf="!profile.user.displayName"
          fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px"
          (click)="sectionService.closeOthers($event, 'account')"
          class="clickable"
          href="/account"
          matTooltip="Login Here"
          matTooltipClass="tooltip"
        >
          <div>
            <i class="material-icons md-light md-36">
              account_circle
            </i>
          </div>
          <div style="color: #f3f3f3; font-size: 20px">
            Login
          </div>
        </a>

        <a
          (click)="sectionService.closeAll($event)"
          class="clickable"
          routerLink="/"
          href="/"
          matTooltip="Events Gallery"
          matTooltipClass="tooltip"
        >
          <div style="width: 36px">
            <img src="/assets/images/events-icon.png">
          </div>
        </a>

        <a
          *ngIf="profile.user.email"
          (click)="sectionService.closeOthers($event, 'favorites')"
          class="clickable"
          style="position: relative;"
          routerLink="/favorites"
          href="/favorites"
        >
          <i
            class="material-icons md-36 md-light"
          >
            favorite_border
          </i>
          <div
            *ngIf="profile.roles.authenticated"
            style="color: #f3f3f3; position: absolute; left: 50%; transform: translateX(-50%); top: 10px; font-size: 12px;"
          >
            {{ auth.favoritesCount }}
          </div>
        </a>
        <a
          *ngIf="!profile.user.email"
          (click)="sectionService.closeOthers($event, 'account')"
          class="clickable"
          routerLink="/"
          href="/favorites"
          matTooltip="Sign in to use the Favorites"
          matTooltipClass="tooltip"
        >
          <i
            class="material-icons md-36 md-light md-inactive"
          >
            favorite_border
          </i>
        </a>

        <app-cart-icon *ngIf="profile.user.email" [type]="'header'"></app-cart-icon>
        <a
          *ngIf="!profile.user.email"
          (click)="sectionService.closeOthers($event, 'account')"
          class="clickable"
          routerLink="/"
          href="/cart"
          matTooltip="Sign in to use the Shopping Cart"
          matTooltipClass="tooltip"
        >
          <i
            class="material-icons md-36 md-light md-inactive"
          >
            shopping_cart
          </i>
        </a>

      </div>
    </div>
  `,
  selector: "app-header",
})
export class HeaderComponent {

  constructor(
    public auth: AuthService,
    public sectionService: SectionService,
  ) {
  }

}
