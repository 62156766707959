import { Injectable } from "@angular/core"
import { CartOption } from "interfaces/cart.interface"
import { BehaviorSubject, Observable } from "rxjs"

@Injectable({
  providedIn: "root"
})
export class CartOptionsService {
  cartOptions: BehaviorSubject<CartOption[]>
  cartOptions$: Observable<CartOption[]>
  membershipOptions: CartOption[]
  printOptions: CartOption[]
  posterOptions: CartOption[]
  collageOptions: CartOption[]
  digitalOptions: CartOption[]

  constructor() {
    this.cartOptions = new BehaviorSubject<any>([])
    this.cartOptions$ = this.cartOptions.asObservable()
    this.digitalOptions = [
      {
        dollars: "$8.50",
        option: "1600px Digital",
        price: 850,
        singular: true,
        type: "digital",
      },
      {
        dollars: "$19.00",
        option: "3200px Digital",
        price: 1900,
        singular: true,
        type: "digital",
      },
    ]
    this.printOptions = [
      {
        dollars: "$7.50",
        option: "4x6 Print",
        price: 750,
        singular: false,
        type: "small print",
      },
      {
        dollars: "$9.50",
        option: "5x7 Print",
        price: 950,
        singular: false,
        type: "small print",
      },
      {
        dollars: "$15.00",
        option: "8x10 Print",
        price: 1500,
        singular: false,
        type: "large print",
      },
    ]
    this.posterOptions = [
      {
        dollars: "$25.00",
        option: "13x19 Poster",
        price: 2500,
        singular: false,
        type: "large print",
      },
      {
        dollars: "$35.00",
        option: "17x22 Poster",
        price: 3500,
        singular: false,
        type: "large print",
      },
    ]
    this.collageOptions = [
      {
        dollars: "$60.00",
        option: "13x19 Collage",
        price: 6000,
        singular: false,
        type: "collage",
      },
      {
        dollars: "$70.00",
        option: "17x22 Collage",
        price: 7000,
        singular: false,
        type: "collage",
      },
      {
        dollars: "$100.00",
        option: "Team Collage",
        price: 10000,
        singular: false,
        type: "collage",
      },
      {
        dollars: "$100.00",
        option: "Family Collage",
        price: 10000,
        singular: false,
        type: "collage",
      },
    ]
    this.membershipOptions = [
      {
        dollars: "$50.00",
        option: "Membership",
        price: 5000,
        singular: true,
        type: "membership",
      }
    ]
    this.addOptions(this.digitalOptions)
    this.addOptions(this.printOptions)
    this.addOptions(this.posterOptions)
    this.addOptions(this.collageOptions)
  }

  private addOptions(options: CartOption[]) {
    const cartOptions = this.cartOptions.value
    const newOptions = [...cartOptions, ...options]
    this.cartOptions.next(newOptions)
  }

}
