import { Component, Inject, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { DomSanitizer } from "@angular/platform-browser"
import { GalleryService } from "services/gallery.service"
import { Observable } from "rxjs"
import { AuthService } from "services/auth.service"
import { FavoritesService } from "services/favorites.service"
import { ImagesService } from "services/images.service"
import { Data, Image } from "interfaces/image.interface"
import { Profile } from "interfaces/account.interface"

@Component({
  styles: [`
    .action-step {
      position: absolute;
      top: 40%;
      left: 40%;
      opacity: 0;
    }

    /*&:hover {*/
    /*  .action-step {*/
    /*    opacity: 1;*/
    /*  }*/
    
    /*  .new-features {*/
    /*    opacity: 0;*/
    /*  }*/
    /*}*/
  `],
  template: `
    <ng-container *ngIf="auth.profile$ | async as profile">
      <div
        mat-dialog-content
        class="center"
      >

        <i
          *ngIf="!downloadLink && data.images.length > 1"
          (click)="loadPrevious()"
          class="material-icons md-36"
        >
          keyboard_arrow_left
        </i>
        <ng-container *ngIf="profile.roles.authenticated && image !== undefined">
          <ng-container *ngIf="auth.favorites$ | async as favorites">
            <i
              *ngIf="favorites[image.filename]"
              class="material-icons md-36"
              matTooltip="Remove from Favorites"
              matTooltipClass="tooltip"
              (click)="favoritesService.toggleFavorite(profile.user.email, favorites, image.filepath, false)"
            >
              favorite
            </i>
            <i
              *ngIf="!favorites[image.filename]"
              class="material-icons md-36"
              matTooltip="Add to Favorites"
              matTooltipClass="tooltip"
              (click)="favoritesService.toggleFavorite(profile.user.email, favorites, image.filepath, true)"
            >
              favorite_border
            </i>
          </ng-container>
        </ng-container>

        <i
          *ngIf="!profile.user.email"
          class="material-icons md-36 md-dark md-inactive"
          matTooltip="Login to use the Shopping Cart"
          matTooltipClass="tooltip"
        >
          add_shopping_cart
        </i>
        <span
          *ngIf="auth.user.email && cartOptions"
          style="display: inline-block"
        >
        <app-cart-icon
          (click)="iconClick($event)"
          [image]="image"
          type="dialog"
        ></app-cart-icon>
      </span>

        <i
          class="material-icons md-36"
          matTooltip="Sharing coming Soon"
          matTooltipClass="tooltip"
        >
          share
        </i>
        <i
          *ngIf="!downloadLink && data.images.length > 1"
          (click)="loadNext()"
          class="material-icons md-36"
        >
          keyboard_arrow_right
        </i>

        <section
          *ngIf="auth.user.email && cartOptions"
          class="cart-options center show"
        >
          <app-cart-options [image]="image"></app-cart-options>
        </section>

        <ng-container *ngIf="!galleryService.public">
          <ng-container *ngIf="profile.roles.authenticated">
            <div
              *ngIf="profile.memberships.annual && profile.memberships.annual < imageYear"
              class="new-features-wrapper"
              style="position: relative;"
            >
              <button
                [mat-dialog-close]="{'update': true}"
              >
                <div class="action-step">
                  click here to update your membership
                </div>
                <div class="new-features">
                  <div style="margin-left: auto; margin-right: auto;">
                    <div
                      class="mat-button"
                      style="font-size: 2em; white-space: normal;"
                    >
                      Update Your Membership
                    </div>
                    <div class="text">
                      For $50 you can view, save and download photos at 800 pixels for current and past years
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <div
              *ngIf="!profile.memberships.annual"
              class="new-features-wrapper"
              style="position: relative;"
            >
              <button
                [mat-dialog-close]="{'join': true}"
              >
                <div class="action-step">
                  click here to add a membership
                </div>
                <div class="new-features">
                  <div style="margin-left: auto; margin-right: auto;">
                    <div
                      class="mat-button"
                      style="font-size: 2em; white-space: normal;"
                    >
                      Add a Membership
                    </div>
                    <div class="text">
                      For $50 you can view, save and download photos at 800 pixels for current and past years
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="!profile.roles.authenticated">
            <div
              class="new-features-wrapper"
              style="position: relative;"
            >
              <button
                [mat-dialog-close]="{'login': true}"
              >
                <div class="action-step">
                  click here to login
                </div>
                <div class="new-features">
                  <div style="margin-left: auto; margin-right: auto;">
                    <div style="font-size: 2em; white-space: normal;">
                      Login to Add, Update, or use your Membership
                    </div>
                    <div class="text">
                      For $50 you can view, save and download photos at 800 pixels for current and past years
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </ng-container>
        </ng-container>

        <div
          *ngIf="data['digital'] === '1600px Digital' || data['digital'] === '3200px Digital' || galleryService.public"
        >
          <div *ngIf="data['digital'] === '3200px Digital'">
            <div>
              Your 3200px Digital is sent to your email within 48 hours of your purchase.
            </div>
            <div>
              You can also download the 1600px Digital immediately.
            </div>
          </div>
          <div *ngIf="download$ | async; let download; else getButton">
            <ng-container *ngIf="download['downloadUrl']; else waitingForUrl">
              <button mat-raised-button>
                <a
                  matTooltip="then right-click the image to download it"
                  aria-label="then right-click the image to download it"
                  [download]="download['filename']"
                  [title]="download['filename']"
                  [href]="download['downloadUrl']"
                  target="_blank"
                  rel="noopener"
                >
                  Open 1600px image in new tab
                </a>
              </button>
            </ng-container>
            <ng-template #waitingForUrl>
              waiting...
            </ng-template>
          </div>

          <ng-template #getButton>
            <button
              mat-raised-button
              (click)="downloadUrl(image.filepath)"
              class="clickable mat-button"
              id="downloadElement"
            >
              get 1600px image
            </button>
          </ng-template>
        </div>

        <div
          class="align-center image-wrapper"
          style="max-width: 800px;"
        >
          {{ image.filepath }}
          <img
            [src]="getUrl(profile, image.filepath, imageYear, galleryService.public)"
            [alt]="image.filename"
          >
        </div>
      </div>

      <div class="padding-1em">
        Download: right-click the image (or long-press, tap and hold)
      </div>
    </ng-container>
  `,
  selector: "app-image-dialog",
})
export class ImageDialogComponent implements OnInit {
  image: Image
  tags: string[]
  // width: number;
  // height: number;
  // favorite: boolean;
  downloadLink: string
  isNext: boolean
  isPrevious: boolean
  imageYear: number
  download$: Observable<any> | boolean
  cartOptions = true

  constructor(
    public auth: AuthService,
    private afs: AngularFirestore,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    public favoritesService: FavoritesService,
    public galleryService: GalleryService,
    private sanitizer: DomSanitizer,
    private imageService: ImagesService,
  ) {
  }

  ngOnInit() {
    this.updateData()
    if (this.image !== undefined) {
      this.imageYear = parseInt(this.image.filepath.split("/")[0], 10)
    }
  }

  iconClick(event) {
    if (event) {
      event.preventDefault()
    }
    this.dialogRef.close({ goToCart: true })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  loadPrevious() {
    this.download$ = false
    this.data.index--
    if (this.data.index === -1) {
      this.data.index = this.data.images.length - 1
    }
    const image = this.data.images[this.data.index]


    /**
     * Skip the previous image if its dimensions are not valid.
     */
    /*
        if (image.width === undefined || !image.width) {
          this.loadPrevious();
        }
    */
    this.updateData()
    this.resetDialog()
  }

  loadNext() {
    // console.log('image-dialog:loadNext()');
    // console.log(this.data.images);
    this.download$ = false
    this.data.index++
    if (this.data.index === this.data.images.length) {
      this.data.index = 0
    }
    // const image = this.data.images[this.data.index];

    /**
     * Skip the next image if its dimensions are not valid.
     */
    /*
        if (image.width === undefined || !image.width) {
          this.loadNext();
        }
    */
    this.updateData()
    this.galleryService.addMoreImages(24)
    this.resetDialog()
  }

  private resetDialog() {
    this.downloadLink = null
  }

  private updateData() {
    if (this.data.images[this.data.index] !== undefined) {
      this.image = this.data.images[this.data.index]
      this.tags = this.data.images[this.data.index].tags
      // this.width = this.data.images[this.data.index].width;
      // this.height = this.data.images[this.data.index].height;
      // this.favorite = this.data.images[this.data.index].favorite;
      // this.galleryService.setRoute(this.image);
    }
  }

  getUrl(profile: Profile, path: string, imageYear: number, publicGallery: boolean): string {
    if (this.downloadLink) {
      return path
    } else {
      if (imageYear <= profile.memberships.annual || publicGallery) {
        return this.imageService.getUrl(path, 800)
      } else {
        return this.imageService.getUrl(path, 400)
      }
    }
  }

  /**
   * triggers the downloadUrl cloud function, this function then populates the key with a temporary download url
   */
  downloadUrl(path: string) {
    const timestamp = Date.now()
    const newDocId = this.afs.createId()
    this.afs
      .collection("files")
      .doc(newDocId)
      .set({
        id: newDocId,
        downloadUrl: "",
        filename: path.split("/").pop(),
        path: "private-1600/" + path,
        timestamp: timestamp
      })
      .then()
      .catch()

    this.download$ = this.afs
      .collection("files")
      .doc(newDocId)
      .valueChanges()
  }

  /*
    getStyle(profile, width, imageYear) {
      // console.log(profile);
      // console.log(width);
      // console.log(imageYear);
      // console.log(profile.memberships.annual);

      if (profile.memberships.annual && profile.memberships.annual >= imageYear) {
        return this.sanitizer.bypassSecurityTrustStyle('max-width: 800px; min-height: 800 px;');
      }
      return this.sanitizer.bypassSecurityTrustStyle('max-width: ' + 800 / 2 + 'px; min-height: ' + 800 / 2 + 'px;');
    }
  */

}
